import React, { useState } from "react";
import { Button, Container, Row, Col, Accordion, Nav, Tabs, Tab } from "react-bootstrap";
import './ProductPage.css'

export const Product = () => {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true); 
    return (
        <div className="ProductPage">

            <section className="product-page-main text-start">
                <Container>
                    <Row>
                        <h1 className="mb-3">
                            Try and buy SAP Business <br /> Technology Platform
                        </h1>
                        <p>
                            Get the best pricing for accessing our SAP Business <br /> Technology Platform cloud services.
                        </p>
                        <div className='text-start'>
                            <Button >Request For Demo</Button>
                        </div>
                    </Row>

                </Container>

            </section>
            <section>
                <Row className="sap-sapphire text-light align-items-center justify-content-center">
                    <Col lg={3}>
                        <h2>SAP Sapphire</h2>
                        <p>
                            &ASUG Annual  Conference ORLANDO
                        </p>
                    </Col>
                    <Col lg={3}>
                        <p>
                            Accelerate innovation to unlock Your business Potential, June 3-5.
                        </p>
                    </Col>
                    <Col lg={3}>
                        <p>
                            <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                            Register Now</p>
                    </Col>

                </Row>
            </section>

            <section className="mt-3 py-5 bg-light">
                <Container>
                    <Row className="try-sap">
                        <h2>
                            Try SAP BTP before you buy
                        </h2>
                        <div className="bar"></div>
                        <p>
                            Let's discover which option suits your needs.
                        </p>
                    </Row>
                    <Row>
                        <Col lg={6} className="text-start sap-btp">
                            <h4>SAP BTP trial</h4>
                            <ul>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Evaluate SAP BTP services for 90 days
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Learn about and evaluate services
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Start quickly with learning materials and tutorials
                                    </p>
                                </li>
                            </ul>
                            <a href="#"> <h6>Start your free 90-days trail</h6></a>

                            <h4 className="mt-5">Free tier for SAP BTP</h4>
                            <ul>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                        Get hands-on experience at no cost
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                        Try and test free services
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                        Fast track results with accelerators and missions that include step-by-step guidance
                                    </p>
                                </li>
                            </ul>
                            <a href="#"><h6>Always free and free tier services</h6></a>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mt-2 py-5">
                <Container>
                    <Row className="sap-price">
                        <h2>
                            SAP BTP Pricing Options
                        </h2>
                        <div className="bar"></div>
                        <p>We offer three different SAP BTP pricing options to fit your needs. </p>
                    </Row>
                    <Row className="mt-5">
                        <Col lg={4} className="text-start pay-as">
                            <div className="pay-card">
                            <h4>Pay-As-You-Go for SAP BTP</h4>
                            <h5 className="mt-5 mb-3">USD
                                0.00</h5>
                                <div>
                                    <h6>Features:</h6>
                                    <ul>
                                    <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Build small-scale apps paying only for services used.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Use for proof-of-concepts and small-scale pilots.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Pay only for what you use beyond free limits.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Includes access to free tier model for learning, prototyping, and testing.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Estimate your usage and evaluate your monthly cost. Access the “Local Estimate” in the SAP Discovery Center by clicking in the edit pencil icon.
                                    </p>
                                </li>
                                    </ul>
                                </div>
                                </div>
                                <Row className=" p-5">
                                <Button>Request For Demo</Button>
                                </Row>
                                
                        </Col>

                        <Col lg={4} className="text-start pay-as">
                            <div className="pay-card">
                            <h4>SAP BTP Enterprise Agreement</h4>
                            <h5 className="mt-5 mb-3">USD
                            10,826.00
                            </h5>
                                <div>
                                    <h6>Features:</h6>
                                    <ul>
                                    <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Consumption-based model to support innovation.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Use for multiple use cases with dynamic requirements over time.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Flexible service options at discounted prices with predictable billing.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Includes access to free tier model for learning, prototyping, and testing.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Estimate your usage and evaluate your monthly cost. Access the “Local Estimate” in the SAP Discovery Center by clicking in the edit pencil icon.
                                    </p>
                                </li>
                                    </ul>
                                    
                                </div>
                                </div>
                                <Row className="p-5">
                                <Button>Buy Now</Button>
                                </Row>
                                
                        </Col>

                        <Col lg={4} className="text-start subscription">
                            <div className="pay-card">
                            <h4>Subscription</h4>
                            <h6 className="mt-5 mb-5">
                            </h6>
                                <div className="mt-5 py-5">
                                    <h6>Features:</h6>
                                    <ul>
                                    <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Use specified cloud services at a fixed cost.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Best for specific use cases with known usage of individual services.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Most favorable commercial terms with committed prices.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-check" aria-hidden="true"></i>

                                        Access to services for use in production with predictable pricing and contract length.
                                    </p>
                                </li>
                                
                                    </ul>
                                    
                                </div>
                                </div>
                                <Row className="p-5">
                                <Button>Explore the SAP BTP subscripition services</Button>
                                </Row>
                               
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mt-3 py-5 bg-light">
                <Container>
                    <Row className="test-start right-sap">
                        <h2>
                        Let’s get you to the right SAP BTP offering
                        </h2>
                        <div className="bar"></div>
                        <p>
                        Not sure which offering is for you? Use our selector tool below to choose the option that best aligns with your role and your needs.
                        </p>
                    </Row>
                    {/* <Row>
                        <Col lg={12} className="progress-bar">
                            <ol>
                                <li className="progress-bar___step progress-bar___step--active">
                                    <span>Your Role</span>

                                </li>
                                <li className="progress-bar___step ">
                                    <span>Your Role</span>

                                </li>
                                <li className="progress-bar___step">
                                    <span>Your Role</span>

                                </li>
                            </ol>

                        </Col>
                    </Row> */}

                    <Row className="py-5 mt-5">
                    <Tabs
      defaultActiveKey="Your Role"
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey="Your Role" title="Your Role">
        <Row className="py-5">
            <h3 className="py-4">Tell us about yourself.</h3>
            <Col lg={4} className="">
                <div className="your-role">
                    <p>
                    Independent developer or working for a company interested in SAP solutions
                    </p>
                </div>
            </Col>
            <Col lg={4}>
                <div className="your-role">
                    <p>
                    Working for SAP partner company
                    </p>
                </div>
            </Col>
            <Col lg={4}>
                <div className="your-role">
                    <p>
                    A student or instructor from an academic institution
                    </p>
                </div>
            </Col>
        </Row>
      </Tab>
      <Tab eventKey="Needs" title="Your Needs">
      <Row className="py-5">
        <h3 className="py-3">What is your primary reason for using SAP Business Technology Platform?</h3>
            <Col lg={4} className="">
                <div className="your-role">
                    <p>
                    I want to familiarize myself and experiment with SAP BTP
                    </p>
                </div>
            </Col>
            <Col lg={4}>
                <div className="your-role">
                    <p>
                    Need to complete course assignment or develop teaching materials
                    </p>
                </div>
            </Col>
            <Col lg={4}>
                <div className="your-role">
                    <p>
                    Working on a research or innovation project
                    </p>
                </div>
            </Col>
        </Row>
      </Tab>
      <Tab eventKey="Result" title="Result">
      <Row className="py-5">
            <Col lg={6} className="py-3">
                <img src={require('../Assets/Productpage/product-image.jpg')} className="result-image"/>
            </Col>
            <Col lg={4} className="text-start">
                <h5 className="py-3">SAP BTP trial</h5>
                <h6>Key Benefits:</h6>
                <ul className="">
                    <li>
                        <p>
                        Access a trial environment of SAP BTP
                        </p>
                    </li>
                    <li>
                        <p>
                        Experiment with a wide range of services for SAP BTP
                        </p>
                    </li>
                    <li>
                        <p>
                        Ramp up with learning materials such as tutorials and missions
                        </p>
                    </li>
                    <li>
                        <p>
                        Explore more than 30 trial services in SAP Discovery Center
                        </p>
                    </li>
                    <li>
                        <p>
                        Learn about the SAP Next Gen innovation projects
                        </p>
                    </li>
                    <li>
                        <p>
                        You can check out if your institution is already a member of the SAP University Alliances and contact us to learn more about the benefits and to become a member
                        </p>
                    </li>

                </ul>
                <Button className="mt-5 mx-5">Acccess To your 90-days SAP BTP trial</Button>
            </Col>
        </Row>
      </Tab>
      
    </Tabs>
                    </Row>
                </Container>
            </section>

            <section className="mt-4">
                <Container>
                    <Row className="py-5">
                        <h2>
                        Frequently Asked Questions
                        </h2>
                        <div className="bar"></div>
                    </Row>
                    <Row className="text-start mb-5 general-sap">
                        <p>
                        General SAP BTP questions
                        </p>
                        <hr/>
                        <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is the difference between  SAP BTP  trial and  the free  tier  model  For SAP  BTP?</Accordion.Header>
        <Accordion.Body>
        SAP BTP trial is a non-commercial, time-limited offering that gives you 90 days to try out a selection of our services in a test environment. At the end of the trial period, your SAP BTP trial account will be shut down. All subaccounts, deployed applications, and services in this trial account will be terminated. All data storage and configurations associated with the trial account will be deleted.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>How are  Services  and Consumption managed?</Accordion.Header>
        <Accordion.Body>
        Once you sign the SAP BTPEA, customer administrators get access to the SAP BTP cockpit to view all currently available services in a global account. There, they can manage their service consumption across subaccounts and see the actual consumption. Furthermore, monthly balance statements are sent to the commercial contact mentioned in the SAP BTPEA to provide transparency on the charges incurred. For Pay-As-You-Go for SAP BTP, you will be billed one month in arrears for services used the prior month. Additionally, the balance statements are available through the SAP for Me mobile app.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                    </Row>
                    <Row className="text-start mt-5 py-5 general-sap">
                        <p>
                        General SAP BTPEA Questions
                        </p>
                        <hr/>
                        <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is SAP BTPEA?</Accordion.Header>
        <Accordion.Body>
        SAP BTPEA is a consumption-based agreement that provides access to many SAP Business Technology Platform services.  Billing for these services will be based on usage as opposed to subscription. This is an evolution of the existing Cloud Platform Enterprise Agreement.
        <br/>
        Similarly to CPEA, with SAP BTPEA you will be given credits over the course of your contract that will be consumed as services are activated.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What are the benefits of SAP BTPEA?</Accordion.Header>
        <Accordion.Body>
        Aligning agreements to customer usage provides greater linkage to value. There are additional services in SAP BTPEA such as SAP Analytics Cloud, public system option and that will enhance customer experience and innovation.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>What's the new  deprecation Policy?</Accordion.Header>
        <Accordion.Body>
        New deprecation policy 
        <ul>
            <li>
                <p>
                Group 1: Full support through end of current contract term
                </p>
            </li>
            <li>
                <p>
                Group 2: New innovation services, which may either be rolled into group 1 over time or removed
                </p>
            </li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>What happens if i exceed  my prepaid Credits?</Accordion.Header>
        <Accordion.Body>
        Any usage beyond available cloud credits for the respective period results in overages, which are invoiced monthly at list price.  

You can find pricing on the Discovery Center Service Catalogue.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>What Services  are avaliable on SAP BTPEA?</Accordion.Header>
        <Accordion.Body>
        A full list of services available under SAP BTPEA can be found in the SAP Discovery Center.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Where can i go to learn more?</Accordion.Header>
        <Accordion.Body>
        Additional information on SAP BTPEA can be found in the SAP Discovery Center and in the SAP Help Portal.
<br/>
For a better understanding of SAP Business Technology Platform, visit the SAP BTP Onboarding Resource Center and attend a SAP BTP Onboarding Webinar 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>How do I get Started with SAP BTPEA Credits?</Accordion.Header>
        <Accordion.Body>
        Once your SAP BTPEA contract starts, you will have access to your SAP BTP cockpit In your SAP BTP cockpit is where you will be able to add users, create subaccounts, and activate/deactivate services that will consume your SAP BTPEA credits. (for new customers, the Contact IT Person will receive an email with your access instructions)
<br/>
For more information, you can visit the SAP BTP Onboarding Resource Center for tutorials or book a one-on-one session to get started.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                    </Row>
                    <Row className="mt-5 py-5 text-start general-sap">
                        <p>
                        Existing customers
                        </p>
                        <hr/>
                        <Accordion>
      
      <Accordion.Item eventKey="1">
        <Accordion.Header>Can i stay on CPEA?</Accordion.Header>
        <Accordion.Body>
        Customers with existing CPEA contracts may continue to use and renew CPEA.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Should i move from  CPEA to SPA BTPEA?</Accordion.Header>
        <Accordion.Body>
        This needs to be analyzed on a case-by-case basis. Existing CPEA Customers can move to SAP BTPEA when it is right for them and should evaluate this at contract renewal. Customers can remain on CPEA, however, to benefit from SAP’s latest innovations, SAP BTPEA should be evaluated. 
        <ul>
            <li>
                
                Group 1: Full support through end of current contract term
                
            </li>
            <li>
                
                Group 2: New innovation services, which may either be rolled into group 1 over time or removed
                
            </li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Will all services in CPEA be available in SAP BTPEA?</Accordion.Header>
        <Accordion.Body>
        Not all CPEA services will be available in SAP BTPEA. There will be new key services available such as SAP Analytics Cloud, public system option. This accelerates innovation for our customers. 

Please see SAP Discovery Center .
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Can I use CPEA and SAP BTPEA?</Accordion.Header>
        <Accordion.Body>
        Customers can have both CPEA and SAP BTPEA but they need to be in separate Global Accounts. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Where do I go for more information regarding moving from CPEA to SAP BTPEA?</Accordion.Header>
        <Accordion.Body>
        Please see the SAP BTPEA section of the Help Portal.

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>Can i transfer my exisiting SAP Analytics Cloud Subscription to SAP BTPEA?</Accordion.Header>
        <Accordion.Body>
        Customers with existing SAP Analytics Cloud subscriptions cannot transfer without a change of contract. It is important to note that whilst SAP BTPEA will offer customers increased flexibility of their SAP Analytics Cloud deployments, subscriptions may be more cost effective for mature customers with well-defined use cases. It is also worth noting that currently we only offer SAP Analytics Cloud public option, which is best suited for smaller deployments.  
<br/>
For more information, you can visit the SAP BTP Onboarding Resource Center for tutorials or book a one-on-one session to get started.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>Can i move my existing SAP Analytics Cloud. Public system option assets to SAP BTPEA? </Accordion.Header>
        <Accordion.Body>
        Customers with existing SAP Analytics Cloud subscriptions cannot transfer without a change of contract. It is important to note that whilst SAP BTPEA will offer customers increased flexibility of their SAP Analytics Cloud deployments, subscriptions may be more cost effective for mature customers with well-defined use cases. It is also worth noting that currently we only offer SAP Analytics Cloud, public system option, which is best suited for smaller deployments.  
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                    </Row>
                    <Row className="mt-5 py-5 text-start general-sap">
                        <p>
                        SAP BTP trial period and account deletion policy
                        </p>
                        <hr/>
                        <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is the duration of the trial Period  and is there minimum activity required?</Accordion.Header>
        <Accordion.Body>
        The trial period duration is 90 days from the date of account registration. Keeping the trial account active during the trial period requires at least one login within a 30-day period. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What happens if I don't log in Within 30 Days?</Accordion.Header>
        <Accordion.Body>
        If your account remains inactive for 30 consecutive days during the trial period, it will be subject to deletion. However, you can choose to initiate a new SAP BTP trial. 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                    </Row>
                    <Row className="mt-5 py-5 text-start general-sap">
                        <p>
                        SAP partners
                        </p>
                        <hr/>
                        <Accordion>
                        <Accordion.Item eventKey="0">
        <Accordion.Header>How my company become an SAP partner?</Accordion.Header>
        <Accordion.Body>
        If you are interested in becoming an SAP partner, visit the SAP Partner Program. There you can learn more about the engagement models we offer and apply for the model that best fits your business needs.  
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Is SAP BTPEA is available for partners?</Accordion.Header>
        <Accordion.Body>
        Yes.  Please visit PartnerEdge to learn more about SAP BTPEA for partners. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Can SAP Partners get access for test , demo , and development purpose?</Accordion.Header>
        <Accordion.Body>
        Yes, SAP partners can get access to test, demo, and development licenses for SAP BTP services. Please visit the SAP Partner Portal to learn more.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                    </Row>
                    <div>
                        <Button>Information on Exisiting  CPEA</Button>
                    </div>
                </Container>
            </section>

        </div>
    )
}