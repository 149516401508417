import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ModalPopUp } from '../ModalPopupComponent/ModalpopUp'
import './Publications.css'

export const Publications = () => {
  return (
    <div>
         <section className="SmartIG-main">
                <Container>
                    <Row>
                        <Col lg={7} data-aos="fade-right">
                            <div className="text-start">
                                <h1>
                                    Publications
                                </h1>
                                <p>
                                    Ingest data from disparate resources, create interactive and compelling dashboards, capture KPIs and insights, and share reports with the blend of advanced data analytics and visualization tools backed by AI and ML.
                                </p>
                                <ModalPopUp  />
                                {/* <Button className="mt-5 bg-light text-dark" data-aos="fade-up">Request For Demo</Button> */}
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <Row className='text-center d-flex justify-content-center align-items-center'>
                        <Col>
                        <div>
                            <img src={require('../Assets/DataIntegration/data-help-u.png')} alt="integral"/>
                        </div>
                        </Col>
                        <Col>
                        <div>
                            <h2>News and Updates</h2>
                            <p>
                                you can find the latest news here
                            </p>
                        </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <Row className='text-center d-flex justify-content-center align-items-center'>
                    <Col>
                        <div>
                            <h2>Blogs</h2>
                            <p>
                                you can find the Blogs
                            </p>
                        </div>
                        </Col>
                        <Col>
                        <div>
                            <img src={require('../Assets/DataIntegration/data-help-u.png')} alt='integral'/>
                        </div>
                        </Col>
                       
                    </Row>
                </Container>
            </section>
    </div>
  )
}
