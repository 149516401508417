// import React, { UseEffect } from "react";
import { Button, Col, Container, Nav, Row, Tab, TabContent } from "react-bootstrap";
import './SaasComponent.css'
import Aos from 'aos';
import { useEffect } from "react";
import CheckIcon from '@mui/icons-material/Check';
import TableAcordian from "./TableAcordian";

export const Saas = () => {
    useEffect(() => {
        // Initialize AOS library
        Aos.init({
            disable: "phone",
            duration: 1000,
            // easing: "ease-out-cubic",
        });

        // Refresh AOS library (if needed)
        Aos.refresh();
    }, []);


    return (
        <div className="saas">
            <section className="saas-main ">
                <Container>
                    <Row className="text-start">
                        <Col lg={7} data-aos="fade-right">
                            <h1>
                                Choose the plan that aligns with your needs!
                            </h1>
                            <p>
                                Explore our data analytics and visualization tool with pricing options tailored to your flexibility needs. Unlock in-depth insights without constraints with our adaptable pricing model.
                            </p>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="py-5 price">
                <Container>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="link-1">
                        <Row className="align-items-center justify-content-center">
                            <Col lg={6} md={8} sm={12} xs={12} className="price-tab  ">
                                <Nav variant="pills" className="active-tab justify-content-center align-items-center text-center">
                                    <Nav.Item>
                                        <Nav.Link eventKey="link-1" className="tab-1">Analytics</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="link-2" className="tab-1" >
                                            Embedded
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>
                        <Row className="align-items-center justify-content-center">
                            <Col lg={12}>
                                <TabContent>
                                    <Tab.Pane eventKey="link-1">
                                        <Row className="align-items-center justify-content-center py-5">
                                            <Col lg={3} >
                                                <div className="bg-light text-start p-3 price-card ">
                                                    <h2>
                                                        Team
                                                    </h2>
                                                    <p className="price-content">
                                                        For individuals and small teams to use AI-powered search to visualize their data
                                                    </p>
                                                    <div className="d-flex  ">
                                                        <h2>$95</h2>
                                                        <p>
                                                            /month
                                                        </p>
                                                    </div>
                                                    <p>
                                                        5 users <br />Billed monthly
                                                    </p>

                                                    <Button className="w-100 py-2 mb-4">Try for free</Button>
                                                    <div className=" d-flex align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>
                                                            Up to 5 million rows of data
                                                        </p>

                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>
                                                            1 permission group
                                                        </p>
                                                    </div>

                                                    <div className="d-flex align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>
                                                            Natural language search
                                                        </p>
                                                    </div>

                                                    <div className="d-flex align-items-center ">
                                                        <CheckIcon className="price-icon" />
                                                        <p>
                                                            In-app and community-based support
                                                        </p>
                                                    </div>

                                                    <div className="d-flex align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>

                                                            Essential security controls
                                                        </p>
                                                    </div>


                                                </div>
                                            </Col>

                                            <Col lg={3} >
                                                <div className="bg-light text-start p-3 price-card" >
                                                    <h2>
                                                        Essentials

                                                    </h2>
                                                    <p className=" price-content">

                                                        For small companies to share AI-powered insights across teams
                                                    </p>
                                                    <div className="d-flex  ">
                                                        <h2>$1250</h2>
                                                        <p>
                                                            /month
                                                        </p>
                                                    </div>
                                                    <p className="">
                                                        20 users <br />Billed annually
                                                    </p>

                                                    <Button className="w-100 py-2 mb-4 ">Try for free</Button>
                                                    <div className="d-flex align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>
                                                            Up to 25 million rows of data
                                                        </p>
                                                    </div>
                                                    <div className="d-flex  align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>
                                                            5 permission groups
                                                        </p>
                                                    </div>

                                                    <div className="d-flex align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>

                                                            Natural language search
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>

                                                            Automated insights
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                    <CheckIcon className="price-icon" />
                                                    <p>
                                                       
                                                       Essential security controls
                                                   </p>
                                                    </div>

                                                   
                                                    <div className="d-flex align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>

                                                            In-app and community-based support

                                                        </p>
                                                    </div>

                                                    <div className="d-flex align-items-center">
                                                    <CheckIcon className="price-icon" />
                                                    <p>
                                                       
                                                       Row-level security
                                                   </p>
                                                    </div>
                                                   <div className="d-flex align-items-center">
                                                   <CheckIcon className="price-icon" />
                                                   <p>
                                                      
                                                      Essential security controls
                                                  </p>
                                                   </div>

                                                   

                                                </div>
                                            </Col>

                                            <Col lg={3} className="pro" >

                                                <div className="bg-light text-start text-dark p-2 feature-pro ">
                                                    <h5 className=" most-popular">Most Popular</h5>
                                                    <h2 className="pt-4">
                                                        Pro
                                                    </h2>
                                                    <p className=" price-content">
                                                        For growing businesses to give everyone AI-powered insights on more data
                                                    </p>
                                                    <div className="d-flex  ">

                                                        <p className="">
                                                            Custom
                                                        </p>
                                                    </div>
                                                    <p>
                                                        Unlimited users <br />Billed monthly
                                                    </p>

                                                    <Button className="w-100 py-2 mb-3">Try for free</Button>
                                                    <div className="d-flex align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>

                                                            Up to 500 million rows of data
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                    <CheckIcon className="price-icon" />
                                                    <p>
                                                       
                                                        25 permission groups

                                                    </p>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                    <CheckIcon className="price-icon" />
                                                    <p>
                                                        
                                                        Automated insights
                                                    </p>
                                                    </div>
                                                   <div className="d-flex align-items-center">
                                                   <CheckIcon className="price-icon" />
                                                   <p>
                                                        
                                                        Natural language search
                                                    </p>
                                                   </div>

                                                   <div className="d-flex align-items-center">
                                                   <CheckIcon className="price-icon" />
                                                   <p>
                                                        
                                                        Automated insights
                                                    </p>
                                                   </div>

                                                   <div className="d-flex align-items-center">
                                                   <CheckIcon className="price-icon" />
                                                   <p>
                                                       
                                                       In-app and 24x7 support
                                                   </p>
                                                   </div>

                                                   <div className="d-flex align-items-center">
                                                   <CheckIcon className="price-icon" />
                                                   <p>
                                                       
                                                       Pro level support

                                                   </p>
                                                   </div>

                                                   <div className="d-flex align-items-center">
                                                   <CheckIcon className="price-icon" />
                                                   <p>
                                                      
                                                      Standard security controls
                                                  </p>
                                                   </div>
                                                </div>
                                            </Col>

                                            <Col lg={3} >
                                                <div className="bg-light text-start p-3 price-card">
                                                    <h2>
                                                        Enterprise

                                                    </h2>
                                                    <p className=" price-content">
                                                        For large enterprises to scale AI-powered analytics with enterprise-grade controls
                                                    </p>
                                                    <div className="d-flex  ">

                                                        <p className="">
                                                            Custom

                                                        </p>
                                                    </div>
                                                    <p className="mt-3">
                                                        Unlimited users
                                                    </p>

                                                    <Button className="w-100 py-2 mb-3 mt-4">Try for free</Button>

                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        Unlimited data
                                                    </p>
                                                    <div className="d-flex align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>
                                                            Unlimited permission groups
                                                        </p>

                                                    </div>

                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        Orgs enabled
                                                    </p>

                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        Natural language search
                                                    </p>

                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        Automated insights
                                                    </p>

                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        In-app and 24x7 support

                                                    </p>

                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        Enterprise level support

                                                    </p>
                                                    <div className="d-flex">
                                                        <CheckIcon className="price-icon" />
                                                        <p>

                                                            Enterprise security controls
                                                        </p>
                                                    </div>


                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="link-2">
                                        <Row className="align-items-center justify-content-center py-5">
                                            <Col lg={3}>
                                                <div className="bg-light text-start p-3 price-card ">
                                                    <h2>
                                                        Developer
                                                    </h2>
                                                    <p className=" price-content">
                                                        For individuals and small developer teams to create embed analytics apps quickly
                                                    </p>
                                                    <div className="d-flex  mb-3">
                                                        <h2>Free</h2>

                                                    </div>
                                                    <p>
                                                        5 users <br />Register online<br />Limit 1 year term
                                                    </p>

                                                    <Button className="w-100 py-2 mb-4">Try for free</Button>
                                                    <div className="d-flex align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>
                                                            Up to 3 million rows of data
                                                        </p>
                                                    </div>


                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        2 permission groups

                                                    </p>

                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        20 object limit *
                                                    </p>

                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        Natural language search
                                                    </p>
                                                    <div className="d-flex align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>
                                                            In-app and Discord chat support
                                                        </p>
                                                    </div>

                                                    <div className="d-flex align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>
                                                            Access to APIs and dev tools

                                                        </p>
                                                    </div>


                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        Essential security controls

                                                    </p>

                                                    <p className="mt-4">
                                                        * Limit of 5 worksheets, 5 answers, 10 liveboards

                                                    </p>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="bg-light text-start p-3 price-card ">
                                                    <h2>
                                                        Pro
                                                    </h2>
                                                    <p className="price-content ">
                                                        Build or embed analytics for single-tenant, medium scale applications
                                                    </p>
                                                    <div className="d-flex   py-4">

                                                        <p>
                                                            Custom

                                                        </p>
                                                    </div>
                                                    <p className="mt-2">
                                                        Unlimited users <br />Billed annually
                                                    </p>

                                                    <Button className="w-100 py-2 mb-4">Try for free</Button>
                                                    <div className="d-flex align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>
                                                            Up to 5 billion rows of data
                                                        </p>
                                                    </div>


                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        500 permission groups

                                                    </p>

                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        Natural language search
                                                    </p>

                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        Automated insights
                                                    </p>

                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        In-app and 24x7 support
                                                    </p>

                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        Pro level support

                                                    </p>

                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        Standard security controls
                                                    </p>
                                                    <div className="d-flex">
                                                        <CheckIcon className="price-icon" />
                                                        <p>
                                                            Access to APIs and dev tools
                                                        </p>
                                                    </div>


                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="bg-light text-start p-3 price-card ">
                                                    <h2>
                                                        Enterprise
                                                    </h2>
                                                    <p className=" price-content">
                                                        For individuals and small teams to use AI-powered search to visualize their data
                                                    </p>
                                                    <div className="d-flex  mb-4 py-2">
                                                        <h2>$95</h2>
                                                        <p>
                                                            /month
                                                        </p>
                                                    </div>
                                                    <p>
                                                        5 users <br />Billed monthly
                                                    </p>

                                                    <Button className="w-100 py-2 mb-4">Try for free</Button>
                                                    <div className="d-flex align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>
                                                            Up to 5 million rows of data
                                                        </p>
                                                    </div>

                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        1 permission group

                                                    </p>

                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        Natural language search
                                                    </p>
                                                    <div className="d-flex align-items-center">
                                                        <CheckIcon className="price-icon" />
                                                        <p>
                                                            In-app and community-based support
                                                        </p>
                                                    </div>


                                                    <p>
                                                        <CheckIcon className="price-icon" />
                                                        Essential security controls
                                                    </p>

                                                </div>
                                            </Col>
                                        </Row>

                                    </Tab.Pane>
                                </TabContent>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </section>

            <section className="py-5">
                <Container>
                    <Row className="align-items-center justify-content-center">
                        <Col lg={11} md={10} sm={12} xs={12}>

                            <TableAcordian />

                        </Col>
                    </Row>
                </Container>
            </section>





        </div>
    )

}