import React, { useState } from 'react';
import './HeaderTop.css';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { ModalPopUp } from '../ModalPopupComponent/ModalpopUp'; // Import ModalPopUp component

export const HeaderTop = () => {
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [click, setClick] = useState(false);
  const [color, setColor] = useState(false);

  const handleCloseModal = () => setShowModal(false); // Function to close modal
  const handleShowModal = () => setShowModal(true); // Function to show modal

  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  const handleClick = () => setClick(!click);

  return (

    <div>

      <Navbar expand="lg" className='fixed-top nav-bar-top' >
        <Container >
          <Navbar.Brand href='#'>
            <img className='w-50' src={require('../Assets/Footer/logo.png')} alt='header-logo' /></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="ms-auto  my-lg-0" style={{ maxHeight: '600px' }} navbarScroll>
              <Link className="nav-item nav-link" to="/">Home</Link>
              {/* <Link className="nav-item nav-link" to="about-us">About</Link> */}

              <div class="nav-item dropdown">
                <Link to="/Smart-IG" className="nav-link"> Products  </Link>
                <div class="dropdown-menu border-light m-0">
                  <Link to="/data-migration" className="dropdown-item">Data Migration</Link>
                  <Link to="/data-analytics-2" className="dropdown-item">Data Analytics</Link>
                </div>
              </div>
              <Link className="nav-item nav-link" to="Saas">SaaS</Link>

              <div class="nav-item dropdown">
                <Link to="" className="nav-link"> Publications  </Link>
                <div class="dropdown-menu border-light m-0">
                  <Link to="/news-events" className="dropdown-item">News And Events</Link>
                  <Link to="/Blogs" className="dropdown-item">Blogs</Link>

                </div>
              </div>
              <Link className="nav-item nav-link" to="contact-us">Contact</Link>
              <Nav.Link >
                <ModalPopUp show={showModal} onHide={handleCloseModal} />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </div>
  );
};
