import React ,{useState} from "react";
import { Button,  Modal, ModalBody, ModalHeader, ModalTitle} from "react-bootstrap";
import { FormComponent } from "../ContactUS/FormComponent";
// import emailjs from '@emailjs/browser';

export const ModalPopUp = () => {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    return(
        <div>
           <Button onClick={handleShow}>Book a Demo</Button>
           <Modal show={show} onHide={handleClose} className="">
            <ModalHeader closeButton >
            <ModalTitle className="py-2">Book a free Demo </ModalTitle>
            </ModalHeader>
            <ModalBody>

              <FormComponent/>
                
            </ModalBody>
           </Modal>
        </div>
    )
}