import React, { useEffect, useState } from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap';
import './HomeComponent.css'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import StorageIcon from '@mui/icons-material/Storage';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import InsightsIcon from '@mui/icons-material/Insights';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Aos from 'aos';

export const HomeComponent = () => {


    const [currentIndex, setCurrentIndex] = useState(0);

    const slides = [
        <div key={1}>
            <Row>
                <Col lg={12}>
                    <div className="outer-test">
                        <div className="inner-test">
                            <Row className="align-items-center justify-content-center py-5">
                                <Col lg={5}>
                                <div className='text-start text-dark'>
                                <h4 >
                                Christofer
                                    </h4>
                                    <h5>
                                    FMCG industry
                                    </h5>
                                </div>
                                   
                                    <div className="test-image ">

                                        <img src={require('../Assets/DataIntegration/testmonial-1.jpg')} alt="integration" className=" w-100" />

                                    </div>

                                </Col>
                                <Col lg={5} className='text-start'  >
                                    <div className="curtain py-5 px-5">
                                        <h3> Simple, but an effective tool!</h3>
                                        <p className=" text-start py-2">
                                            We wholeheartedly endorse Integral Analytical Solutions. The tool is best at retrieving deep insights with attention-grabbing visualizations. It has plenty of cool, productive, and time-saving features. We appreciate the team’s results-driven mindset, initiatives to anticipate our business requirements, crystal-clear communication, and client management capabilities. Overall, it is a powerful data analytics and visualization tool!.
                                        </p>


                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>,

        <div key={2}>
            <Row>
                <Col lg={12}>
                    <div className="outer-test">
                        <div className="inner-test">
                            <Row className="align-items-center justify-content-center py-5">
                                <Col lg={5}>
                                <div className='text-start text-dark'>
                                <h4 >
                                Neil Chowdary,
                                    </h4>
                                    <h5>
                                    Sales & Marketing 
                                    </h5>
                                </div>
                                    <div className="test-image">
                                        <img src={require('../Assets/DataIntegration/testmonial-1.jpg')} alt="integration" className=" w-100" />

                                    </div>

                                </Col>
                                <Col lg={5}>
                                    <div className="curtain p-5 text-start">
                                        <h3> Best tool for in-depth insights!  </h3>
                                        <p className="py-2">
                                            The tool helped us understand our business a lot. We delved deep into our data at a granular level and uncovered hidden patterns, trends, and opportunities with the help of its innovative Copilot and AI-powered insights. This tool helped us make informed and strategic decisions that improved our sales and revenue. I highly recommend the tool for every business!
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>,

        <div key={3}>
            <Row>
                <Col lg={12}>
                    <div className="outer-test">
                        <div className="inner-test">
                            <Row className="align-items-center justify-content-center py-5">
                                <Col lg={5}>
                                <div className='text-start text-dark'>
                                <h4 >
                                Nissy Jain
                                    </h4>
                                    <h5>
                                    Financial sector
                                    </h5>
                                </div>
                                    <div className="test-image">
                                        <img src={require('../Assets/DataIntegration/testmonial-1.jpg')} alt="integration" className=" w-100" />

                                    </div>

                                </Col>
                                <Col lg={5}>
                                    <div className="curtain text-start p-5">
                                        <h3> Best tool for data analytics and visualization!  </h3>
                                        <p className=" py-2">
                                            This tool comes with data migration, integration, analytics, and visualization. It is rare to have such a solution. We rest assured that the tool ensures data quality and integrity, facilitates advanced analytics, mind-blowing visuals with different types of graphs and charts, customization features, AI-powered insights, and many more.



                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>,


    ];

    const goToNextSlide = () => {
        const nextIndex = (currentIndex + 1) % slides.length;
        setCurrentIndex(nextIndex);
    };

    const goToPreviousSlide = () => {
        const nextIndex = currentIndex === 0 ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(nextIndex);
    };

    useEffect(() => {
        // Initialize AOS library
        Aos.init({
            disable: "phone",
            duration: 1500,
            // easing: "ease-out-cubic", 
        });

        // Refresh AOS library (if needed)
        Aos.refresh();
    }, []);


    return (
        <div className='hero-sec'>
            {/* Hero section */}
            <section className='hero-section'>
                <div>
                    <Container>
                        <Row>
                            <Col>

                                <div className='hero-content text-start' data-aos="fade-right">
                                    <h1 >
                                        Simplifying Complexity for Enterprise Analytics
                                    </h1>
                                    <h6>Reestablish control over your data and reduce uncertainty with world-class data analytics and visualization tool! </h6>
                                    <div className='text-start'>
                                        <Link to="contact-us"><Button className='hero-btn'>Reach Now</Button> </Link>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row className='hero-cards' data-aos="fade-up">
                            <Col lg={4} md={8}>
                                <div className='text-light hero-card'>
                                    <div>
                                        <AutoAwesomeIcon />

                                    </div>
                                    <div>
                                        <h4> Seamless  Data Migration</h4>
                                        <p> Streamline data flow in every step, with precision in every byte!. Migrate your data with efficiency, minimal disruption, and zero downtime. </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={8}>
                                <div className='text-light hero-card'>
                                    <div>
                                        <AutoAwesomeIcon />

                                    </div>
                                    <div>
                                        <h4>Extracting insights swiftly </h4>
                                        <p>Process, collect, cleanse, and analyze data to get underlying patterns and hidden insights in it.</p>
                                    </div>
                                </div></Col>
                            <Col lg={4} md={8}>
                                <div className='text-light hero-card'>
                                    <div>
                                        <AutoAwesomeIcon />

                                    </div>
                                    <div>
                                        <h4>Smart Infographics</h4>
                                        <p>Transform complex data into a compelling analytical story and ensure meaningful outcomes using advanced data visualization. </p>
                                    </div>
                                </div></Col>
                        </Row>
                    </Container>
                </div>

            </section>

            <section className='py-5 key-challenges-main mt-5'>
                <Container>
                    <Row>
                        <h2>
                            Key Challenges Today’s Businesses Face!
                        </h2>
                        <div className="bar"></div>
                    </Row>
                    <Row className='align-items-center justify-content-center py-5'>
                        <Col lg={5}>
                            <div className="what-we-offer-home">
                                <div className="we-offer-2">
                                    <h4 className='py-3'>
                                        {/* Data Migration */}
                                        We don’t just migrate, we optimize
                                    </h4>
                                </div>
                                <h5 className='py-3 text-start px-4 '>

                                </h5>
                                <p className=' text-start px-4'>
                                    Don’t let unorganized sets of files and unstructured databases hinder the success of your organization. Transfer your important data between platforms and systems with our top-notch data migration services. From flawless data migration to powerful ETL processes and seamless integration, our team of experts ensure efficiency and accuracy, data quality and integrity, zero downtime, and enhanced data utilization. Wait not to streamline your business operations and maximize the value of your data assets.
                                </p>
                                <Button className='my-3 px-4 py-2'>Read More</Button>
                            </div>
                        </Col>
                        <Col lg={7}>

                        <img src={require('../Assets/Home/new-2.1.gif')} className='w-100' alt='data-migration'/>
                        </Col>
                    </Row>

                    <Row >
                        <Col lg={7}>
                        <img src={require('../Assets/Home/new2.2.gif')} className='w-100' alt='data-analytics'/>
                        </Col>

                         <Col lg={5}>
                            <div className="what-we-offer-home">
                                <div className="we-offer-2">
                                    <h4 className='py-3'>
                                        {/* Data Analytics */}
                                        Actionable insights through compelling visualizations
                                    </h4>
                                </div>
                                <h5 className='py-3'>

                                </h5>
                                <p className=' text-start px-4'>
                                    Transform raw data into useful insights with our integrated data analytics and visualization tool. From profound analysis of intricate datasets to creating captivating visuals, we leverage cutting-edge tools to uncover patterns, trends, and opportunities, and empower businesses with data-driven decision-making and actionable insights. Explore our advanced features such as predictive & prescriptive analytics, real-time dashboards, CXO Dashboards, On-demand real-time Infographics & Cockpit Views, etc. that propel your business forward.
                                </p>
                                <Button className='my-3 px-4 py-2'>Read More</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

           

            <section className='why-choose-integral'>
                <Container>
                    <Row className='integral-choose-container align-items-center justify-content-center'>
                        <Row className='integral-choose-heading mb-3 '>
                            <h2>Why Choose Integral?</h2>
                            <div className='bar'></div>
                        </Row>
                        <Col lg={6}>

                            <Row >
                                <Col>
                                    <div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Seamless Data Migration</h6>
                                        </div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Reduced Overhead Costs </h6>
                                        </div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Flexibility & Agility</h6>
                                        </div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Minimized Redundant Data</h6>
                                        </div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Enhanced Security</h6>
                                        </div>


                                    </div>

                                </Col>
                                <Col>
                                    <div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Robust Data Integration</h6>
                                        </div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Data Quality & Integrity </h6>
                                        </div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6> Rapid Data Connectivity</h6>
                                        </div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Better Collaboration</h6>
                                        </div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Unified Data Governance</h6>
                                        </div>


                                    </div>

                                </Col>
                            </Row>

                        </Col>
                        <Col lg={6}>
                            <div data-aos="fade-left">
                                <img src={require('../Assets/Home/Why Choose Integral-1.png')} className='w-75' alt='why-choose-us-img1' />
                            </div>
                        </Col>
                    </Row>

                    <Row className='integral-choose-container  alignoitems-center justify-content-center'>
                        <Col lg={6}>
                            <div data-aos="fade-right">
                                <img src={require('../Assets/Home/Why Choose Integral2.png')} className='w-100' alt='why-choose-us-img1' />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <Row>
                                <Col>
                                    <div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Predictive & Prescriptive Analytics</h6>
                                        </div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Actionable & AI-driven Insights </h6>
                                        </div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Faster Decision-making</h6>
                                        </div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Canned Reports</h6>
                                        </div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Customized Reports</h6>
                                        </div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Advanced Filters </h6>
                                        </div>



                                    </div>

                                </Col>
                                <Col>
                                    <div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6> Real-time Infographics</h6>
                                        </div>
                                        {/* <div className='services-box'>
                                            <StorageIcon />
                                            <h6>visual representation</h6>
                                        </div> */}
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Unlimited Dashboards & Charts</h6>
                                        </div>
                                        {/* <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Drive performance </h6>
                                        </div> */}
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Cockpit Views</h6>
                                        </div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Copilot</h6>
                                        </div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Trends Creation</h6>
                                        </div>
                                        <div className='services-box'>
                                            <StorageIcon />
                                            <h6>Increased Efficiency & ROI </h6>
                                        </div>


                                    </div>

                                </Col>
                            </Row>

                        </Col>

                    </Row>
                </Container>
            </section>

            <section className='benefits '>
                <Container>
                    <div>
                        <div>
                            <h2>Benefits of our data analytics and data visualization tool</h2>
                            <div className='bar'></div>
                        </div>
                    </div>
                    <Row >
                        <Col lg={4} md={6} data-aos="fade-up">
                            <div>
                                <div class="services-box-item">
                                    <div class="icon">
                                        <img src={require('../Assets/Home/Decision-Making-01.png')} alt='Decision-making-img' />
                                    </div>
                                    <div className='s-heading'>
                                        <h3>
                                            <Link className='service-heading'> Intuitive UI</Link>
                                        </h3>
                                        <p>Embrace an attractive user interface with a seamless user experience. Through drag-and-drop objects, responsive layouts, and intuitive navigation, users can represent complex findings clearly and dynamically with insights-driven visuals.  </p>
                                        <Link className="learn-more-btn" to='/about-us'> <ArrowRightIcon />Learn More </Link>

                                    </div>

                                    <div class="shape">
                                        <img src='https://startp-next.envytheme.com/images/bigdata-analytics/rectangle.png' alt='bid-data-analytics' />

                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} data-aos="fade-up">
                            <div>
                                <div class="services-box-item">
                                    <div class="icon">
                                        <img src={require('../Assets/Home/Operational Efficiency-01.png')} alt='operational-efficiency' />

                                    </div>
                                    <div className='s-heading'>
                                        <h3>
                                            <Link className='service-heading'>No infrastructure silos</Link>
                                        </h3>
                                        <p>Data migration avoids creating isolated system structures or storage, thereby ensuring seamless integration across platforms and efficient utilization of existing infrastructure.  </p>
                                        <Link className="learn-more-btn" to='/about-us'> <ArrowRightIcon />Learn More </Link>

                                    </div>

                                    <div class="shape">
                                        <img src='https://startp-next.envytheme.com/images/bigdata-analytics/rectangle.png' alt='data-analytics' />

                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} data-aos="fade-up">
                            <div>
                                <div class="services-box-item">
                                    <div class="icon">
                                        <img src={require('../Assets/Home/Enhanced Data Quality-01.png')} alt='Enhanced-data' />

                                    </div>
                                    <div className='s-heading'>
                                        <h3>
                                            <Link className='service-heading'>Informed decision-making</Link>
                                        </h3>
                                        <p>Speed up the process of decision-making 5x times faster with our data analytics and smart infographics tool equipped with business intelligence capabilities and compelling visuals.
                                        </p>
                                        <Link className="learn-more-btn" to='/about-us'> <ArrowRightIcon />Learn More </Link>

                                    </div>

                                    <div class="shape">
                                        <img src='https://startp-next.envytheme.com/images/bigdata-analytics/rectangle.png' alt='Enhanced-data' />

                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} data-aos="fade-up">
                            <div>
                                <div class="services-box-item">
                                    <div class="icon">
                                        <img src={require('../Assets/Home/Smarter and Faster Reporting-01.png')} alt='smater-faster' />
                                    </div>
                                    <div className='s-heading'>
                                        <h3>
                                            <Link className='service-heading'>Improved operational efficiency </Link>
                                        </h3>
                                        <p>Streamline business processes, identify bottlenecks, and optimize performance thus improving the operational efficiency of your organization. </p>
                                        <Link className="learn-more-btn" to='/about-us'> <ArrowRightIcon />Learn More </Link>

                                    </div>

                                    <div class="shape">
                                        <img src='https://startp-next.envytheme.com/images/bigdata-analytics/rectangle.png' alt='data-analytics' />

                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} data-aos="fade-up">
                            <div>
                                <div class="services-box-item">
                                    <div class="icon">
                                        <img src={require('../Assets/Home/Spot Trends and Patterns-01.png')} alt='spot-trends' />
                                    </div>
                                    <div className='s-heading'>
                                        <h3>
                                            <Link className='service-heading'>Enhanced data quality</Link>
                                        </h3>
                                        <p>Data integration software consolidates data by removing duplicates and data inconsistencies. Thus, it enables organizations to have a holistic view of customers, business operations, and market landscape on a unified platform.</p>
                                        <Link className="learn-more-btn" to='/about-us'> <ArrowRightIcon />Learn More </Link>

                                    </div>

                                    <div class="shape">
                                        <img src='https://startp-next.envytheme.com/images/bigdata-analytics/rectangle.png' alt='data-analytics' />

                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} data-aos="fade-up">
                            <div>
                                <div class="services-box-item">
                                    <div class="icon">
                                        <img src={require('../Assets/Home/Intuitive UI-01.png')} alt='intuitive-ui' />
                                    </div>
                                    <div className='s-heading'>
                                        <h3>
                                            <Link className='service-heading'>Powerful analytics</Link>
                                        </h3>
                                        <p>Our tool is best at data interpretation and deep insights. Unveil business performance in all areas, customers’ behaviour patterns, and the industry landscape. Spotting trends and patterns let you identify areas for improvement and give you an edge over your competitors.</p>
                                        <Link className="learn-more-btn" to='/about-us'> <ArrowRightIcon />Learn More </Link>

                                    </div>

                                    <div class="shape">
                                        <img src='https://startp-next.envytheme.com/images/bigdata-analytics/rectangle.png' alt='data-analytics' />

                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} data-aos="fade-up">
                            <div>
                                <div class="services-box-item">
                                    <div class="icon">
                                        <img src={require('../Assets/Home/copilot-ai.png')} alt='intuitive-ui' />
                                    </div>
                                    <div className='s-heading'>
                                        <h3>
                                            <Link className='service-heading'>Insights powered by Copilot & AI</Link>
                                        </h3>
                                        <p>Have the best of both worlds!. Benefit from both our tool's and AI's suggested actionable insights. Also, avail of AI-driven chart suggestions based on the file you exported.</p>
                                        <Link className="learn-more-btn" to='/about-us'> <ArrowRightIcon />Learn More </Link>

                                    </div>

                                    <div class="shape">
                                        <img src='https://startp-next.envytheme.com/images/bigdata-analytics/rectangle.png' alt='data-analytics' />

                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} data-aos="fade-up">
                            <div>
                                <div class="services-box-item">
                                    <div class="icon">
                                        <img src={require('../Assets/Home/Fast-report.png')} alt='intuitive-ui' />
                                    </div>
                                    <div className='s-heading'>
                                        <h3>
                                            <Link className='service-heading'>Visualize Smart, Report Fast</Link>
                                        </h3>
                                        <p>Smart IG backed by BI and data transformation capabilities lets you create on-demand real-time infographics with advanced filters, dashboards, charts, and cards. Download customized, canned, and user-configured reports without hassles. </p>
                                        <Link className="learn-more-btn" to='/about-us'> <ArrowRightIcon />Learn More </Link>

                                    </div>

                                    <div class="shape">
                                        <img src='https://startp-next.envytheme.com/images/bigdata-analytics/rectangle.png' alt='data-analytics' />

                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} data-aos="fade-up">
                            <div>
                                <div class="services-box-item">
                                    <div class="icon">
                                        <img src={require('../Assets/Home/garde-security.png')} alt='intuitive-ui' />
                                    </div>
                                    <div className='s-heading'>
                                        <h3>
                                            <Link className='service-heading'>Enterprise-grade security </Link>
                                        </h3>
                                        <p>Protecting your data is our funda. Integral Data Analytics empower businesses with robust security measures with unparalleled data management and governance every step of the way. </p>
                                        <Link className="learn-more-btn" to='/about-us'> <ArrowRightIcon />Learn More </Link>

                                    </div>

                                    <div class="shape">
                                        <img src='https://startp-next.envytheme.com/images/bigdata-analytics/rectangle.png' alt='data-analytics' />

                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section className="py-5 test-main">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="carousel">

                                <div className="slide">
                                    {slides[currentIndex]}
                                </div>

                            </div>
                            <div className="car-btu">
                                <button onClick={goToPreviousSlide} >< ArrowBackIcon /></button>
                                <button onClick={goToNextSlide}><ArrowForwardIcon /></button>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='py-5'>
                <Container>


                    <div className='case-study'>
                        <h2>
                            CASE STUDY
                        </h2>
                        <div className="bar"></div>
                        <Row className='align-items-center justify-content-center  py-5'>

                            <Col lg={6} className='text-start px-5 case-1'>
                                <h4>
                                    Driving sales growth with Integral Analytical Solutions
                                </h4>
                                <p>
                                    In today’s cut-throat competitive business arena, driving revenue growth for sales is a tough row to hoe. Organizations must enhance their sales strategies through data-driven insights. This case study explores how a mid-sized sales company utilized our data analytics and visualization tool to optimize sales performance and achieve significant growth.
                                </p>
                                <h5>
                                    The challenge:
                                </h5>
                                <p>
                                    The sales company has failed to
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            Analyze the key sales drivers and trends
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Improve the accuracy of sales forecasting
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Make better decision-making across the sales team
                                        </p>
                                    </li>
                                </ul>



                            </Col>
                            <Col lg={6}>
                                <img src={require('../Assets/Home/integral-CASE-STUDY-Image.png')} className='w-100 h-100' alt='integral-case-study' />
                            </Col>
                            <Row>
                                <Col lg={12} className='text-start'>
                                    <p style={{ fontSize: '0.95rem' }}>
                                        These three reasons have led to poor sales performance, though they have high-quality products in their store.
                                    </p>
                                    <h4 className='the-solution'>
                                        The solution:
                                    </h4>
                                    <p style={{ fontSize: '0.95rem' }}>
                                        Keeping in mind the issues faced by the client, we suggested that he should have reports developed in Smart InteGraphics. We integrated their CRM software with our tool and fetched all the required data (sales and customers) which went through data ingestion, data transformation, data analytics, and data visualization. We ensured that data was cleansed, structured, and prepared for analysis, enhancing its quality and usability. Then, various dashboards and charts are prepared based on the selected data in which patterns and valuable insights are extracted. All created charts are published and exported for reports. In addition, the AI-driven insights feature in our tool uncovered deeper insights and trends that may not be apparent through traditional analytics. Co-pilot, an AI-powered assistant, complements the process by providing intelligent recommendations and guidance. Finally, the client got a comprehensive framework for turning raw data into actionable insights, driving strategic outcomes and informed decision-making for their sales growth. The client appreciated our suggestions and liked our tool. Here is his review.
                                    </p>
                                    <div className="case-solu">
                                        <p>
                                            “Smart InteGraphics helped us in many ways. The tool exceeded our expectations. We gained more insights than we thought. We just wanted to know about key drivers for sales growth. Fortunately, with the help of AI-driven insights, we unveiled hidden insights as well. The entire team is supportive, and we look forward to utilizing this tool in the future.”
                                        </p>
                                    </div>

                                </Col>
                            </Row>
                        </Row>
                    </div>
                </Container>
            </section>

            <section className='about-us ptb-80'>
                <Container>

                    {/* <Row>
                        <div>
                            <div>
                                <h2 className='about-us-heading'>About US</h2>
                                <div className='bar'></div>
                            </div>

                        </div>
                        
                    </Row> */}
                    <Row>
                        <Col lg={6}>
                            <div className='about-imgs' >
                                <img src='https://startp-next.envytheme.com/_next/static/media/discover-img1.7325c7c1.png' alt='about-img1' data-aos="fade-up" />
                                <img src='https://startp-next.envytheme.com/_next/static/media/discover-img2.d56c1dd8.jpg' alt='about-img2' data-aos="fade-down" />


                            </div>
                        </Col>
                        <Col>
                            <div className='about-content' data-aos="fade-left">
                                <h2>
                                    About Integral Data Analytics Solutions
                                </h2>
                                <p>
                                    Real-time actionable insights are what make businesses anticipate future trends. That’s why at Integral Data Analytics, we have been delivering sophisticated data analytics and visualization services to our customers across the world. We are at the forefront of adopting emerging technologies and tools to create top-notch data analytics and visualization solutions that are tailored to meet diverse and dynamic needs.
                                </p>
                                <p>
                                    We have a team of professionals with expertise and experience in data migration, data integration, data analysis, and data visualization. They filter, aggregate, and analyse data to improve agility and scalability to support existing and future requirements.
                                </p>
                                <Link to='contact-us'><Button className='btn btn-light'>Discover More</Button></Link>

                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className='about-shape1'>
                    <img src='https://startp-next.envytheme.com/_next/static/media/analytics-shape1.01a02156.png' alt='about-shape1' />
                </div>
            </section>


            <section className='cta'>
                <div>
                    <Container>
                        <Row className='cta-row'>
                            <Col lg={7}>
                                <div className='cta-content ' data-aos="fade-left">
                                    <h3>Ready to transform your business with powerful data  analytics & smart infographics?</h3>
                                    <Link to='contact-us'><Button className='cta-btn ' >Get started</Button></Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </div>
    )
}

export default HomeComponent;