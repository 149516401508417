import React, { useState } from "react";
import { Container, Row, Col, Button, Carousel, Card } from "react-bootstrap";
import './DataIntegration.css'
// import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Link } from "react-router-dom";
import Aos from 'aos';
import { useEffect } from 'react'
import { ModalPopUp } from "../ModalPopupComponent/ModalpopUp";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const DataIntegration = () => {
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);


    const [currentIndex, setCurrentIndex] = useState(0);

    const slides = [
        <div key={1}>
            <Row>
                <Col lg={12}>
                    <div className="outer-test">
                        <div className="inner-test">
                            <Row className="align-items-center justify-content-center py-5">
                                <Col lg={5}>
                                    <div className="test-image">
                                        <img src={require('../Assets/DataIntegration/testmonial-1.jpg')} alt="integration" className=" w-100" />

                                    </div>

                                </Col>
                                <Col lg={4}  >
                                    <div className="curtain py-5">
                                        <h3> Simple, but an effective tool!</h3>
                                        <p className="px-3 py-2">
                                        We wholeheartedly endorse Integral Analytical Solutions. The tool is best at retrieving deep insights with attention-grabbing visualizations. It has plenty of cool, productive, and time-saving features. We appreciate the team’s results-driven mindset, initiatives to anticipate our business requirements, crystal-clear communication, and client management capabilities. Overall, it is a powerful data analytics and visualization tool!. 
                                        </p>

                                       
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>,

        <div key={2}>
            <Row>
                <Col lg={12}>
                    <div className="outer-test">
                        <div className="inner-test">
                            <Row className="align-items-center justify-content-center py-5">
                                <Col lg={5}>
                                    <div className="test-image">
                                        <img src={require('../Assets/DataIntegration/testmonial-1.jpg')} alt="integration" className=" w-100" />

                                    </div>

                                </Col>
                                <Col lg={4}>
                                    <div className="curtain py-5">
                                        <h3> Best tool for in-depth insights!  </h3>
                                        <p className="px-3 py-2">
                                        The tool helped us understand our business a lot. We delved deep into our data at a granular level and uncovered hidden patterns, trends, and opportunities with the help of its innovative Copilot and AI-powered insights. This tool helped us make informed and strategic decisions that improved our sales and revenue. I highly recommend the tool for every business!
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>,

        <div key={3}>
            <Row>
                <Col lg={12}>
                    <div className="outer-test">
                        <div className="inner-test">
                            <Row className="align-items-center justify-content-center py-5">
                                <Col lg={5}>
                                    <div className="test-image">
                                        <img src={require('../Assets/DataIntegration/testmonial-1.jpg')} alt="integration" className=" w-100" />

                                    </div>

                                </Col>
                                <Col lg={4}>
                                    <div className="curtain py-5">
                                        <h3> Best tool for data analytics and visualization!  </h3>
                                        <p className="px-3 py-2">
                                        This tool comes with data migration, integration, analytics, and visualization. It is rare to have such a solution. We rest assured that the tool ensures data quality and integrity, facilitates advanced analytics, mind-blowing visuals with different types of graphs and charts, customization features, AI-powered insights, and many more. 



                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>,

        
    ];

    const goToNextSlide = () => {
        const nextIndex = (currentIndex + 1) % slides.length;
        setCurrentIndex(nextIndex);
    };

    const goToPreviousSlide = () => {
        const nextIndex = currentIndex === 0 ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(nextIndex);
    };



    useEffect(() => {
        Aos.init({
            disable: "phone",
            duration: 1000,
            // easing: "ease-out-cubic",
        });
        Aos.refresh();

    }, []);
    return (
        <div className="DataIntegration">
            <section className="dataintegraion-main">
                <Container>
                    <Row>
                        <Col lg={7} >
                            <div className="text-start">
                                <h1>
                                    Import, Create, and Share Slick and Smart Infographics
                                </h1>
                                <p>
                                    Ingest data from disparate resources, create interactive and compelling dashboards, capture KPIs and insights, and share reports with the blend of advanced data analytics and visualization tools backed by AI and ML.
                                </p>
                                <ModalPopUp show={showModal} onHide={handleCloseModal} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="py-5 test-main">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="carousel">

                                <div className="slide">
                                    {slides[currentIndex]}
                                </div>

                            </div>
                            <div className="car-btu">
                            <button onClick={goToPreviousSlide} >< ArrowBackIcon /></button>
                            <button onClick={goToNextSlide}><ArrowForwardIcon/></button>
                            </div>
                           
                        </Col>
                    </Row>
                </Container>
            </section>








            <section>
                <Container>
                    <Row className="align-items-center justify-content-center">
                        <Col lg={7} className="text-start">
                            <h2 className="text-center">
                                We Can Help With
                            </h2>
                            <div className="bar"></div>
                            <ul className="data-help">
                                <li>
                                    <p>
                                        It is streamlining your data operations that will give you more time to focus on strategic things.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Seamless data integration across a wide range of data sources.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        We are optimizing costs by reducing human errors and avoiding repetitive tasks.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Enhancing data security.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Improving data quality, whether you are a Small or a large enterprise.
                                    </p>
                                </li>
                            </ul>
                        </Col>
                        <Col lg={5} data-aos="fade-left">
                            <img src={require('../Assets/DataIntegration/data-help-u.png')} alt="integration" />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='about-us ptb-80'>
                <Container>


                    <Row>
                        <Col lg={5}>
                            <div className='about-imgs'>
                                <img src='https://startp-next.envytheme.com/_next/static/media/discover-img1.7325c7c1.png' alt='about-img1' />
                                {/* <img src='https://startp-next.envytheme.com/_next/static/media/discover-img2.d56c1dd8.jpg' alt='about-img2'/> */}


                            </div>
                        </Col>
                        <Col>
                            <div className='about-content' data-aos="fade-left">
                                <h2>
                                    What do you need to know about Data Integration services?
                                </h2>
                                <p>
                                    Data integration is a process that combines and harmonizes  data from multiple sources, usually from different platforms  or systems into a unified data set or view. Data integration's  primary goal is to present data in a consistent, comprehensive  and accurate manner, making it easier to analyze, report, and  make decisions.
                                </p>
                                <p>
                                    Data integration greatly facilitates the consolidation of all  sorts of data, taking into account its expansion, volume,  and all of its different formats. By combining this work with a single set of data, firms may support internal  departments to agree on business plans and strategies. Make  decisions and create appealing and effective business  hints for both immediate and long-term success. It is a  crucial component of the data pipeline. Combining integration  and The ingest, process, transform, and store of data will assist your company in aggregating data of any form, structure, or volume.
                                </p>
                                <Link to='/about-us'><Button className='btn btn-light'>Discover More</Button></Link>

                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className='about-shape1'>
                    <img src='https://startp-next.envytheme.com/_next/static/media/analytics-shape1.01a02156.png' alt='about-shape1' />
                </div>
            </section>

            <section className="mt-4 bg-light">
                <Row className="py-3">
                    <h3>Our Offerings in Data Integration Services and Solutions</h3>
                    <div className="bar"></div>
                </Row>
                <Container>
                    <Row>
                        <Col>
                            <Carousel interval={2000} nextLabel="" prevLabel="" className="p">
                                <Carousel.Item>
                                    <Row className="py-4">
                                        <Col>
                                            <Card.Body>
                                                <div className="features-boxes text-start ">
                                                    <h5>
                                                        API Integration

                                                    </h5>
                                                    <p>
                                                        Applications can more easily collaborate and communicate in real-time when seamless data and services are shared between them, thanks to API connectivity. It is frequently used in software development to improve the usability and functionality of various applications, resulting in a more uniform and cohesive software atmosphere.
                                                    </p>
                                                </div>

                                            </Card.Body>
                                        </Col>
                                        <Col>
                                            <Card.Body>
                                                <div className="features-boxes text-start ">
                                                    <h5>
                                                        Cloud Integration
                                                    </h5>
                                                    <p>
                                                        This integration allows for the exchange of information and coordination of processes across different cloud platforms, as well as between cloud-based systems and on-premises ones. It enables organizations to optimize operations and fully utilize cloud services. Cloud integration is crucial in modern IT landscapes where cloud solutions are key to digital transformation and efficiency.
                                                    </p>
                                                </div>
                                            </Card.Body>
                                        </Col>

                                    </Row>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Row className="py-4">
                                        <Col>
                                            <Card.Body>
                                                <div className="features-boxes text-start ">
                                                    <h5>
                                                        Data Warehousing
                                                    </h5>
                                                    <p>
                                                        Through the delivery of a consistent and historical view of the data, it is intended to enable tracking, analysis of data, and business intelligence activities. By providing quick access to accurate, consistent, and pertinent data, data warehousing helps organizations make wise decisions. It further improves data integrity and makes getting information from various systems and databases less difficult.
                                                    </p>
                                                </div>

                                            </Card.Body>
                                        </Col>
                                        <Col>
                                            <Card.Body>
                                                <div className="features-boxes text-start ">
                                                    <h5>
                                                        E-Commerce Integration
                                                    </h5>
                                                    <p>
                                                        The process of integrating different elements of an online business, such as website platforms, payment gateways, inventory management systems, and customer databases, is known as e-commerce integration. By enabling the easy exchange of information, transactions, and activities amongst various elements, this integration efforts to develop an efficient and effective e-commerce ecosystem
                                                    </p>
                                                </div>
                                            </Card.Body>
                                        </Col>

                                    </Row>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Row className="py-4">
                                        {/* <Col> */}
                                        <Card.Body>
                                            <div className="features-boxes text-start ">
                                                <h5>
                                                    Enterprise Application Integration
                                                </h5>
                                                <p>
                                                    EAI aims to improve business processes and information flow by facilitating the exchange of data and processes between diverse applications, regardless of the underlying technologies. Organizations may use their existing software investments more effectively and productively thanks to this integration, which also improves data consistency throughout the company. To enable efficient data exchange and communication between various systems, EAI solutions often make use of middleware, connectors, and established protocols.
                                                </p>
                                            </div>

                                        </Card.Body>
                                        {/* </Col> */}

                                    </Row>
                                </Carousel.Item>





                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section className='benefits '>
                <Container>
                    <div>
                        <div>
                            <h2>Benefits of our data analytics and data visualization tool</h2>
                            <div className='bar'></div>

                        </div>

                    </div>
                    <Row >
                        <Col lg={4} data-aos="fade-down">
                            <div>
                                <div class="services-box-item">
                                    <div class="icon">
                                        <img src='https://startp-next.envytheme.com/_next/static/media/icon1.d0082a13.png' alt="integration" />
                                    </div>
                                    <div className='s-heading'>
                                        <h3>
                                            <Link className='service-heading'> Holistic insights</Link>
                                        </h3>
                                        <p>Get a holistic view of your business through the integration of data from different sources. This will lead to deeper insights and improved decision-making. </p>
                                        <Link className="learn-more-btn" to='/about-us'> <ArrowRightIcon />Learn More </Link>
                                        {/* <Link to=''><Button>Learn More</Button></Link> */}

                                    </div>

                                    <div class="shape">
                                        <img src='https://startp-next.envytheme.com/images/bigdata-analytics/rectangle.png' alt="integration" />

                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} data-aos="fade-down">
                            <div>
                                <div class="services-box-item">
                                    <div class="icon">
                                        <img src='https://startp-next.envytheme.com/_next/static/media/icon1.d0082a13.png' alt="integration" />
                                    </div>
                                    <div className='s-heading'>
                                        <h3>
                                            <Link className='service-heading'>Real-Time Advantage</Link>
                                        </h3>
                                        <p>Real-time data integration gives you a competitive advantage by allowing for rapid responses to changes in the market and new opportunities. In today's dynamic environment, this agility is essential to stay on top of things. </p>
                                        <Link className="learn-more-btn" to='/about-us'> <ArrowRightIcon />Learn More </Link>
                                        {/* <Link to=''><Button>Learn More</Button></Link> */}

                                    </div>

                                    <div class="shape">
                                        <img src='https://startp-next.envytheme.com/images/bigdata-analytics/rectangle.png' alt="integration" />

                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} data-aos="fade-down">
                            <div>
                                <div class="services-box-item">
                                    <div class="icon">
                                        <img src='https://startp-next.envytheme.com/_next/static/media/icon1.d0082a13.png' alt="integration" />
                                    </div>
                                    <div className='s-heading'>
                                        <h3>
                                            <Link className='service-heading'>Operational excellence</Link>
                                        </h3>
                                        <p>By reducing procedures and allocating resources more effectively, you may improve productivity and cost-efficiency. Data integration enhances your methods, leading to increased resource efficiency and reduced costs.</p>
                                        <Link className="learn-more-btn" to='/about-us'> <ArrowRightIcon />Learn More </Link>
                                        {/* <Link to=''><Button>Learn More</Button></Link> */}

                                    </div>

                                    <div class="shape">
                                        <img src='https://startp-next.envytheme.com/images/bigdata-analytics/rectangle.png' alt="integration" />

                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} data-aos="fade-up">
                            <div>
                                <div class="services-box-item">
                                    <div class="icon">
                                        <img src='https://startp-next.envytheme.com/_next/static/media/icon1.d0082a13.png' alt="integration" />
                                    </div>
                                    <div className='s-heading'>
                                        <h3>
                                            <Link className='service-heading'>Enhanced Engagement of Customers</Link>
                                        </h3>
                                        <p>integral analytics solutions help you to make data-driven decisions that exceed those of your rivals. Data integration gives you the ability to fully utilize your data, giving you an advantage in your sector. </p>
                                        <Link className="learn-more-btn" to='/about-us'> <ArrowRightIcon />Learn More </Link>
                                        {/* <Link to=''><Button>Learn More</Button></Link> */}

                                    </div>

                                    <div class="shape">
                                        <img src='https://startp-next.envytheme.com/images/bigdata-analytics/rectangle.png' alt="integration" />

                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} bd={6} data-aos="fade-up">
                            <div>
                                <div class="services-box-item">
                                    <div class="icon">
                                        <img src='https://startp-next.envytheme.com/_next/static/media/icon1.d0082a13.png' alt="integration" />
                                    </div>
                                    <div className='s-heading'>
                                        <h3>
                                            <Link className='service-heading'>Competitive Edge</Link>
                                        </h3>
                                        <p>Making data-driven decisions that are better than those of your competitors can help you get valuable insight. By integrating your data, you can take full advantage of its potential and gain an edge over other businesses.</p>
                                        <Link className="learn-more-btn" to='/about-us'> <ArrowRightIcon />Learn More </Link>
                                        {/* <Link to=''><Button>Learn More</Button></Link> */}

                                    </div>

                                    <div class="shape">
                                        <img src='https://startp-next.envytheme.com/images/bigdata-analytics/rectangle.png ' alt="integration" />

                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} bd={6} data-aos="fade-up">
                            <div>
                                <div class="services-box-item">
                                    <div class="icon">
                                        <img src='https://startp-next.envytheme.com/_next/static/media/icon1.d0082a13.png' alt="integration" />
                                    </div>
                                    <div className='s-heading'>
                                        <h3>
                                            <Link className='service-heading'>Business growth</Link>
                                        </h3>
                                        <p>By analyzing your data fully, you can find new possibilities and encourage your goals for development. Data integration turns into a key factor in the development and progress of your company by revealing trends, market inefficiencies, and future chances for growth.</p>
                                        <Link className="learn-more-btn" to='/about-us'> <ArrowRightIcon />Learn More </Link>
                                        {/* <Link to=''><Button>Learn More</Button></Link> */}

                                    </div>

                                    <div class="shape">
                                        <img src='https://startp-next.envytheme.com/images/bigdata-analytics/rectangle.png' alt="integration" />

                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>

            <section className="integral-integration my-5 p-5">
                <Row>
                    <h3>
                        Benefits you Get Integral analytics Data Integration services
                    </h3>
                    <div className="bar"></div>
                </Row>
                <Row className="px-5 pt-5 ">

                    <Col lg={4} className="text-start" >
                        <div className="dashboard-controls">
                            <div className="dashboard-level" >

                                <div>
                                    <i class="fa fa-file-image-o" aria-hidden="true"></i>


                                </div>
                                <p>Download as an images</p>
                            </div>


                        </div>
                    </Col>

                    <Col lg={4} className="text-start" >
                        <div className="dashboard-controls">
                            <div className="dashboard-level" >

                                <div>
                                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>



                                </div>
                                <p>Edit</p>
                            </div>


                        </div>
                    </Col>
                    <Col lg={4} className="text-start" >
                        <div className="dashboard-controls">
                            <div className="dashboard-level" >

                                <div>
                                    <i class="fa fa-line-chart" aria-hidden="true"></i>



                                </div>
                                <p>Trends</p>
                            </div>


                        </div>
                    </Col>
                    <Col lg={4} className="text-start" >
                        <div className="dashboard-controls">
                            <div className="dashboard-level" >

                                <div>
                                    <i class="fa fa-paint-brush" aria-hidden="true"></i>



                                </div>
                                <p>Color Palette</p>
                            </div>


                        </div>
                    </Col>
                    <Col lg={4} className="text-start" >
                        <div className="dashboard-controls">
                            <div className="dashboard-level" >

                                <div>
                                    <i class="fa fa-cogs" aria-hidden="true"></i>




                                </div>
                                <p>Reset</p>
                            </div>


                        </div>
                    </Col>
                    <Col lg={4} className="text-start" >
                        <div className="dashboard-controls">
                            <div className="dashboard-level" >

                                <div>
                                    <i class="fa fa-globe" aria-hidden="true"></i>



                                </div>
                                <p>AI insights</p>
                            </div>


                        </div>
                    </Col>



                </Row>
            </section>

            <section className='cta'>
                <div>
                    <Container>
                        <Row className='cta-row' data-aos="fade-left">
                            <Col lg={7}>
                                <div className='cta-content'>
                                    <h3>Ready to transform your business with powerful data  analytics & smart infographics?</h3>
                                    <Link to='/contact-us'><Button className='cta-btn'>Get started</Button></Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </div>
    )
}     