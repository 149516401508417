// import logo from './logo.svg';
import './App.css';
import { HomeComponent } from './Components/HomeComponent/HomeComponent';
import { HeaderTop } from './Components/HeaderComponent/HeaderTop';
import { Footer } from './Components/FooterComponent/Footer';
// import { AboutUs } from './Components/AboutUS/AboutUs';
import { Route, Routes, useLocation } from 'react-router-dom';
// import { SmartIG } from './Components/SmartIG/SmartIGComponent';
import { Saas } from './Components/Saas Component/SaasComponent';
import { DataMigration } from './Components/DataMigration Component/DataMigration';
// import { ETL } from './Components/ETLcomponent/ETL';
import { Product } from './Components/ProductPage/Productpage';
import { useEffect } from 'react';
import { ModalPopUp } from './Components/ModalPopupComponent/ModalpopUp';
// import { Dataanalytics } from './Components/Data Analyatics Component/Data Analytics';
// import { ChatbotTemplate } from './Components/Chatboat/ChatbotTempalte';
import { Publications } from './Components/PublicationsComponent/Publications';
import { Blogs } from './Components/PublicationsComponent/Blogs';
import { NewsAndEvents } from './Components/PublicationsComponent/NewsAndEvents';
import { Whatsapp } from './Components/WhatsappComponent/whatsapp';
// import { ContactFrom } from './Components/ContactUS/ContactForm';
import { FormComponent } from './Components/ContactUS/FormComponent';
import { DataAnalytics2 } from './Components/Data Analyatics Component/Data Analytics2';
import { SAP } from './Components/PublicationsComponent/SAP';
import { DataIntegration } from './Components/DataIntegrationComponent/DataIntegration';
import { ContactUs } from './Components/ContactUS/ContactUs';


function ScrollToTopOnNavigate() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <div className="App">

      <header>
        <HeaderTop/>
      </header>

      <ScrollToTopOnNavigate />
      <main>
        <Routes>

          <Route path='/' element={<HomeComponent/>}/>
          {/* <Route path='about-us' element={<AboutUs/>}/> */}
          <Route path='contact-us' element={<ContactUs/>}/>
          {/* <Route path='Smart-IG' element={<SmartIG/>}/> */}
          <Route path='Saas' element={<Saas/>}/>
          <Route path='data-migration' element={<DataMigration/>}/>
           {/* <Route path='etl' element={<ETL/>}/>  */}
           <Route path='data-analytics-2' element= {<DataAnalytics2/>}/>
          <Route path='product-page' element={<Product/>}/>
          <Route path='ModalPopup' element={<ModalPopUp/>}/>
          {/* <Route path='data-analytics' element={<Dataanalytics/>}/> */}
          <Route path='publications' element={<Publications/>}/>
          <Route path='Blogs' element={<Blogs/>}/>
          <Route path='news-events' element={<NewsAndEvents/>}/>
          <Route path='whats' element={<Whatsapp/>}/>
          <Route path='form-data' element={<FormComponent/>}/>
          <Route path='DataMigration-with-SmartIG' element={<SAP/>}/>
          <Route path='data-integration' element={<DataIntegration/>}/>
         

        </Routes>
        <Whatsapp/>
       
      </main>

      <footer>
      <Footer/>
      </footer>

    </div>
  );

}

export default App;
