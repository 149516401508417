import { Pagination } from '@mui/material';
import React, {  useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import './Publications.css'

export const NewsAndEvents = () => {

    const itemsPerPage = 6; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);



    const pageData = [
        {
            url: require('../Assets/Blogs/sap-ecc.png'),
            content: 'Simplifying SAP ECC to SAP S/4 HANA Migrations With SmartIG',
            btn: 'Read More',
            btnUrl: '/DataMigration-with-SmartIG'
        },
        
    ]

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = pageData.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        const targetDiv = document.getElementById('blog-div'); // Replace 'your-div-id' with the actual ID of your div
        targetDiv.scrollIntoView({ behavior: 'smooth' }); // Scroll to the top of the div
    };

    return (
        <div>
            <div className='publicaton-news'>
                <Container>
                    <h2>News and Events</h2>
                </Container>

            </div>

            <div className='my-4 py-4' id='blog-div'>
                <Container>
                    <Row>

                        {currentItems.map((item, index) => (
                            <Col lg={4}>
                                <Card className='mt-4' style={{ height: '34rem', width: "24rem" }}>
                                    <Card.Img variant="top" className='blog-img' src={item.url} alt="Publication" />
                                    <Card.Body>

                                        <Card.Text>
                                            {item.content}
                                        </Card.Text>
                                        <hr />
                                        <Link className='blog-card-link' to={item.btnUrl}>{item.btn}</Link>
                                    </Card.Body>
                                </Card>

                            </Col>
                        ))}

                    </Row>
                </Container>

                <div>

                    {pageData.length > itemsPerPage && (
                        <Pagination className='d-flex justify-content-center mt-4'
                            count={Math.ceil(pageData.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}

                            color="primary"
                        />
                    )}
                </div>

            </div>
        </div>
    )
}
