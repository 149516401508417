import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import './DataMigration.css'
import { Link } from 'react-router-dom';
import StorageIcon from '@mui/icons-material/Storage';
// import InputGroup from 'react-bootstrap/InputGroup';
// import Form from 'react-bootstrap/Form';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Aos from 'aos';
import { ModalPopUp } from '../ModalPopupComponent/ModalpopUp';
import Video1 from '../Assets/DataMigration/video-1.mp4'
// import video2 from '../Assets/DataMigration/Videos-BG-6.mov'


export const DataMigration = () => {
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    useEffect(() => {
        // Initialize AOS library
        Aos.init({
            disable: "phone",
            duration: 1000,
            // easing: "ease-out-cubic",
        });

        // Refresh AOS library (if needed)
        Aos.refresh();
    }, []);





    return (
        <div className='Datamigration'>
            <section className="datamigration">
                <Container data-aos="fade-right">
                    <Row className="text-start">
                        <Col lg={7}>
                            <h1>
                                Seamless & Flawless Data Migration & Modernization Platform
                            </h1>
                            <p className='my-4'>
                                Secure, efficient, and affordable data migration to cloud storage for effective business process optimization.

                            </p>
                            <ModalPopUp show={showModal} onHide={handleCloseModal} />
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* supported files */}
            <section className='py-5'>
                <Container>
                    <Row className="text-center py-5 head-line">
                        <h2>
                            Connect Your Data Seamlessly
                        </h2>
                        <div className="bar"></div>
                    </Row>
                    <Row className='text-start py-4'>
                        <h2>
                            Supported File Formats for Seamless Ingestion
                        </h2>
                        <p>
                            Effortlessly integrate data to empower data integration and analytics capabilities across your organization
                        </p>
                    </Row>
                    <Row>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img src={require('../Assets/data-analytics-2/csv.png')} style={{ width: '45%' }} alt='data' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img src={require('../Assets/data-analytics-2/xls.png')} className='w-50' alt='data' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img src={require('../Assets/data-analytics-2/xslx.png')} className='w-50 ' alt='data' />
                            </div>
                        </Col>

                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img src={require('../Assets/data-analytics-2/xml.png')} style={{ width: '40%' }} alt='data' />
                            </div>
                        </Col>

                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img src={require('../Assets/data-analytics-2/txt.png')} className='w-50' alt='data' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img src={require('../Assets/data-analytics-2/json.jpg')} className='w-50' alt='data' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img src={require('../Assets/data-analytics-2/pdf.png')} alt='data' className='w-50' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img src={require('../Assets/data-analytics-2/image.png')} alt='data' className='w-50' />
                            </div>
                        </Col>



                    </Row>
                </Container>
            </section>

            {/* databases */}
            <section className='py-5 '>
                <Container>

                    <row className="text-start">
                        <h2>
                            Seamless Integration Across Top Databases
                        </h2>
                        <p>
                            Ingest data from all your databases for unified data access and improved analytics capabilities.
                        </p>
                    </row>
                    <Row className='mt-5'>

                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img className='w-75' src={require('../Assets/data-analytics-2/oracle.png')} alt='data' />
                            </div>
                        </Col>

                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img className='w-75' src={require('../Assets/data-analytics-2/ibmdb2.png')} alt='data' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img className='w-75' src={require('../Assets/data-analytics-2/MariaDB.png')} alt='data' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img className='w-75' src={require('../Assets/data-analytics-2/mysql.png')} alt='data' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img className='w-75' src={require('../Assets/data-analytics-2/ibm-neteza.jpg')} alt='data' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img className='w-50' src={require('../Assets/data-analytics-2/sybase.png')} alt='data' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img src={require('../Assets/data-analytics-2/mssql-2.webp')} alt='data' className='w-50' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img src={require('../Assets/data-analytics-2/msaccess.jpg')} alt='data' className='w-50' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img src={require('../Assets/data-analytics-2/ibm-informix.png')} alt='data' className='w-75' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img src={require('../Assets/data-analytics-2/teradata.png')} alt='data' className='w-50' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img src={require('../Assets/data-analytics-2/sql-lite.png')} alt='data' className='w-75' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img src={require('../Assets/data-analytics-2/postgresql-2.png')} alt='data' className='w-75' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img className='w-100' src={require('../Assets/data-analytics-2/Mongodb.png')} alt='data' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img src={require('../Assets/data-analytics-2/azure-sql.png')} alt='data' style={{ width: '60%' }} />
                            </div>
                        </Col>


                    </Row>
                </Container>
            </section>



            {/* Erp system */}
            <section className='py-5'>
                <Container>
                    <Row className='text-start'>
                        <h2>
                            Seamlessly merge ERP system data
                        </h2>
                        <p>
                            Unlock actionable insights and drive informed decision-making for enhanced business performance
                        </p>
                    </Row>
                    <Row className=''>

                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img className='w-75' src={require('../Assets/data-analytics-2/oracle.png')} alt='data' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img className='w-75' src={require('../Assets/data-analytics-2/SAP-HANA-logo.png')} alt='data' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img className='w-75' src={require('../Assets/data-analytics-2/SAP-ECC.png')} alt='data' />
                            </div>
                        </Col>
                        <Col lg={2} className=''>
                            <div className="img-box">
                                <img className='w-75' src={require('../Assets/data-analytics-2/Oracle ERP.jpg')} alt='data' />
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>


            {/* scoilal media */}
            <section className='py-5'>
                <Container>
                    <Row className='text-start'>
                        <h2>
                            Incorporate your favorite social media platforms
                        </h2>
                        <p>
                            Connect all your online services for seamless interaction and data sharing between different platforms or systems.
                        </p>
                    </Row>
                    <Row>
                        <Col lg={3}>
                            <div className="second-img">
                                <img className='w-75' src={require('../Assets/data-analytics-2/Facebook-Logo.png')} alt='data' />
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="second-img">
                                <img className='w-75' src={require('../Assets/data-analytics-2/instagram-1.png')} alt='data' />
                            </div>
                        </Col>


                        <Col lg={3}>
                            <div className="second-img">
                                <img className='w-50' src={require('../Assets/data-analytics-2/twitter.png')} alt='data' />
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="second-img">
                                <img className='w-75' src={require('../Assets/data-analytics-2/LinkedIn.png')} alt='data' />
                            </div>
                        </Col>



                    </Row>
                </Container>
            </section>


            <section className='py-5'>
                <Container>
                    <Row>
                        <h2>
                            What We Do With Data Migration?
                        </h2>
                        <div className="bar"></div>
                    </Row>
                    <Row className='py-4 text-start what-we-do align-items-center justify-content-center'>
                        <Col lg={6} >
                            <p>
                                We redefine data migration as a strategic optimization rather than simply transferring data between systems. Rest assured, as our tool assesses your data for quality and integrity. It ensures regulatory compliance, adheres to stringent security measures, and maximizes performance and efficiency. Keep informed in every phase of your data migration process through real-time monitoring to ensure a smooth and seamless transition with zero downtime.
                            </p>
                            <h5 className='py-3'>
                                Experience the difference by unlocking our comprehensive suite of features:
                            </h5>
                            <Row>
                                <Col lg={6}>
                                    <ul>
                                        <li>
                                            Data Quality Assessment
                                        </li>
                                        <li>
                                            Data Validation
                                        </li>
                                        <li>
                                            Performance Optimizer
                                        </li>
                                        <li>
                                            Data Security and Compliance
                                        </li>
                                        <li>
                                            Real Time Monitoring and Alerts
                                        </li>
                                        <li>
                                            Data Transformation and Enrichment
                                        </li>
                                    </ul>
                                </Col>
                                <Col lg={6}>
                                    <ul>
                                        <li>
                                            Cost optimization
                                        </li>
                                        <li>
                                            Predictive Analysis
                                        </li>
                                        <li>
                                            Reporting & Visualization
                                        </li>
                                        <li>
                                            Scalability
                                        </li>
                                        <li>
                                            Data Governance
                                        </li>
                                        <li>
                                            Documentation and Auditing
                                        </li>
                                    </ul></Col>
                            </Row>


                        </Col>
                        <Col lg={6} className='px-5'>
                            <video src={Video1} controls="controls" autoPlay="true" className='w-100' />

                        </Col>
                    </Row>
                </Container>
            </section>


            <section className='bg-light py-5'>
                <Row>
                    <h2>Top Data Migration challenges solved with SmartIG</h2>
                    <div className='bar'></div>
                    <p>
                        Whatever your requirements, we’ve got you covered!. Our Data Migration Solutions ensure seamless and robust data migration, maintaining top-notch quality and precision.
                    </p>
                </Row>
                <Container className='py-5'>
                    <Row data-aos="fade-up">
                        <Col lg={4}>
                            <div className='migration-services pt-5'>
                                <div className='migration-icons '>
                                    <i class="fa fa-user " aria-hidden="true"></i>

                                </div>
                                <h4 className='pt-4'>Data Quality</h4>
                                <p className='py-3'>Poor data quality can significantly impact business operations and decision-making post-migration. We ensure high accuracy and clarity in your data before migrating it to the cloud.  </p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className='migration-services pt-5'>
                                <div className='migration-icons '>
                                    <i class="fa fa-database" aria-hidden="true"></i>
                                </div>
                                <h4 className='pt-4'>Time and Resource Management</h4>
                                <p className='py-3'>Data migration requires thorough planning and ample resources. Our dashboard streamlines complex data processes, saving time and enabling focus on strategic planning. </p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className='migration-services pt-5'>
                                <div className='migration-icons '>
                                    <i class="fa fa-cloud" aria-hidden="true"></i>
                                </div>
                                <h4 className='pt-4'>Data Compatibility</h4>
                                <p className='py-3'>Transitioning data between systems? Ensure compatibility with Smart IG, our data migration tool. Our platform seamlessly integrates diverse data sources: Amazon AWS, Microsoft Azure, Google Cloud, SQL, Excel, Oracle Cloud, SAP, and Google Sheets.    </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='align-items-center justify-content-center' data-aos="fade-up">
                        <Col lg={4}>
                            <div className='migration-services pt-5'>
                                <div className='migration-icons '>
                                    <i class="fa fa-windows" aria-hidden="true"></i>
                                </div>
                                <h4 className='pt-4'>Data Security</h4>
                                <p className='py-3'>Secure your data during migration. SmartIG implements rigorous security measures to prevent breaches, ensuring a safe and protected transition.  </p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className='migration-services pt-5'>
                                <div className='migration-icons '>
                                    <i class="fa fa-database" aria-hidden="true"></i>
                                </div>
                                <h4 className='pt-4'>System Downtime </h4>
                                <p className='py-3'>Reduce downtime during migration to minimize costs. SmartIG utilizes efficient techniques, keeping your business operational and downtime minimal.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section className='types-data' >
                <Container>
                    <Row>
                        <h2 className='fs-1'>
                            Types of Data Migration
                        </h2>
                        <div className="bar"></div>
                    </Row>

                    <Row className='text-start py-3 align-items-center justify-content-center'>
                        <Col lg={6}>
                            <h3>
                                Database Migration
                            </h3>
                            <p>
                                Is it complex to upgrade or switch between various database technologies, diverse structures, and different versions?. No more worries. We handle your database migration by optimizing performance and safeguarding your data quality and integrity.
                            </p>
                        </Col>
                        <Col lg={6}>
                            <img src={require('../Assets/Home/Why Choose Integral-1.png')} className='w-75' alt='what- we-do' />
                        </Col>
                    </Row>

                    <Row className='text-start py-3 align-items-center justify-content-center'>
                        <Col lg={6}>
                            <img src={require('../Assets/Home/Why Choose Integral2.png')} className='w-100' alt='what- we-do' />
                        </Col>
                        <Col lg={6}>
                            <h3>
                                Cloud Migration
                            </h3>
                            <p>
                                Have seamless and secure data transfer from on-premise servers to the cloud or from one cloud to another cloud with data integrity and zero downtime. Optimize data processing and data storage of your organization through cloud platforms such as Amazon Web Services, Azure, and Google Cloud.
                            </p>
                        </Col>
                    </Row>

                    <Row className='text-start py-3 align-items-center justify-content-center'>
                        <Col lg={6}>
                            <h3>
                                Application Migration
                            </h3>
                            <p>
                                Consolidate applications and modernize solutions for better productivity and extended value for your IT infrastructure. Migrate all your applications and software from private to public cloud, or on-premise to cloud for centralized access, better management, and robust security.
                            </p>
                        </Col>
                        <Col lg={6}>
                            <img src={require('../Assets/Home/Why Choose Integral-1.png')} className='w-75' alt='what- we-do' />
                        </Col>
                    </Row>

                    <Row className='text-start py-3 align-items-center justify-content-center'>
                        <Col lg={6}>
                            <img src={require('../Assets/Home/Why Choose Integral2.png')} className='w-100' alt='what- we-do' />
                        </Col>
                        <Col lg={6}>
                            <h3>
                                Platform Migrations
                            </h3>
                            <p>
                                Transfer your data from one platform to another without data corruption or loss. From planning to execution, our data migration experts handle the entire process and ensure smooth data transition by mitigating risks.
                            </p>
                        </Col>
                    </Row>
                    <Row className='text-start py-3 align-items-center justify-content-center'>

                        <Col lg={6}>
                            <h3>
                                Data Center Migration
                            </h3>
                            <p>
                                Data center relocation is mandatory for ever-growing businesses that want to anticipate technological advancement. Migrate your data center for your organizational expansion, regulatory requirements, and a company merger. Our data migration experts ensure smooth transitioning, minimum downtime, and data security throughout the transition.
                            </p>
                        </Col>
                        <Col lg={6}>
                            <img src={require('../Assets/Home/Why Choose Integral2.png')} className='w-100' alt='what- we-do' />
                        </Col>
                    </Row>

                </Container>
            </section>

            <section className='py-5'>
                <Container>
                    <Row >
                        <h2>
                            Data Integration
                        </h2>
                    </Row>
                    <Row className='text-start py-5'>
                        <Col lg={5}>
                            <h4 className='py-4'>
                                Transforming Raw Data Ready for Analysis
                            </h4>
                            <p>
                                Don’t let siloed data become a barrier between collaboration and information sharing among different teams of your organization. Our robust data integration solution simplifies the process of ingesting, processing, and integrating data both structured and unstructured from diverse sources such as business apps, cloud platforms, and databases, ensures your enterprise data is harmonized, and saves your precious time.
                            </p>
                        </Col>
                        <Col lg={7}>
                            <Row className='align-items-center justify-content-center'>
                                <Col lg={3}>
                                    <div className="integration-hexa">
                                        <h6>
                                            API Integration
                                        </h6>
                                    </div>

                                    <div className="integration-hexa mt-5">
                                        <h6>
                                            Cloud Integration
                                        </h6>
                                    </div>
                                </Col>
                                <Col lg={3} >
                                    <div className="integration-hexa">
                                        <h6>
                                            Data Warehousing
                                        </h6>
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="integration-hexa text-center">
                                        <h6>
                                            E-commerce Integration
                                        </h6>
                                    </div>
                                    <div className="integration-hexa mt-5 text-center">
                                        <h6>
                                            Enterprise Application Integration
                                        </h6>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>


            <section className=' bg-light py-5'>
                <Container>
                    <Row>
                        <Col lg={6} className='text-start mb-5'>
                            <div className='services-vtlc '>
                                <h2 className='py-4'>
                                    <i class="fa fa-check-square-o px-2" aria-hidden="true"></i>
                                    Validation
                                </h2>
                                <p className='px-4'>
                                    Pre-Validation transforms the data into a format that is more easily and effectively processed. Pre-validation also checks for technical data issues such as: constraints, data types, length, null check, format mask for date and time, check text case, duplicate data, currency rounding etc. ensuring the quality and integrity of data, the benefit of this is to understand issues before loading the data into the target application. Additionally, it enables users to configure additional Pre-Validation rules based on business needs.
                                </p>
                            </div>
                        </Col>
                        <Col lg={6} className='text-start mb-5'>
                            <div className='services-vtlc'>
                                <h3 className='py-4'>
                                    <i class="fa fa-arrow-circle-right px-2" aria-hidden="true"></i>

                                    Transformation
                                </h3>
                                <p className='px-4'>
                                    Transformation converts data, typically from the format of a source system into the required format of a destination system, rules engine enables users to configure lookup and expression-based transformation rules. Internal or external data can be used for transformation logic. Transformation also depends on various factors like how often source data change does, how differently is data stored in the target system and also the volume. we also provide pre-defined transformation logic for a majority of the source to target migration project needs.
                                </p>
                            </div>
                        </Col>
                        <Col lg={6} className='text-start mb-5'>
                            <div className='services-vtlc'>
                                <h3 className='py-4'>
                                    <i class="fa fa-tasks px-2" aria-hidden="true"></i>

                                    Data Loading
                                </h3>
                                <p className='px-4'>
                                    Integral provides data loading adaptors for many enterprise application environments that pushes processed, cleanse and accurate dat.  These adaptors support setups, master and transactional data (both open and historical) loading.
                                    We also provide high performance packages to load High data volumes within a short time.
                                </p>
                            </div>
                        </Col>
                        <Col lg={6} className='text-start'>
                            <div className='services-vtlc'>
                                <h3 className='py-4'>
                                    <i class="fa fa-clone px-2" aria-hidden="true"></i>

                                    Data Cleansing
                                </h3>
                                <p className='px-4'>
                                    Data comes from a wide variety of sources and in a huge range of formats. So, cleansing will be given utmost priority and it Initially starts with fixing bad data in the data set by standardizing the attributes based on the requirements by de-duplicating and processing the data avoiding unwanted characters, double spaces, trailing whitespaces and checking data type of each column which encapsulates any field that can't be neatly fit as numerical or categorical data. This also Includes element-wise cleaning to get them to a uniform format to get a better understanding of the dataset and enforce consistency.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section className='py-5'>
                <Container>

                    <Row className='integral-choose-container align-items-center justify-content-center'>
                        <Row className='integral-choose-heading mb-3 '>
                            <h2> Features of SmartIG's Data Integration</h2>
                            <div className='bar'></div>
                        </Row>
                        <Col lg={7}>

                            <Row >
                                <Col>
                                    <div>
                                        <div className='data-migration-features'>
                                            <div className="d-flex align-items-center">
                                                <StorageIcon />
                                                <h5 className='px-2'>Efficient Data Operations</h5>
                                            </div>
                                            <p >
                                                Simplify processes, freeing time for strategic initiatives.
                                            </p>
                                        </div>


                                        <div className='data-migration-features'>
                                            <div className="d-flex align-items-center">
                                                <StorageIcon />
                                                <h5 className='px-2'>Unified Integration  Sources </h5>
                                            </div>
                                            <p >
                                                Seamlessly connect diverse data for a cohesive landscape.
                                            </p>
                                        </div>

                                        <div className='data-migration-features'>
                                            <div className="d-flex align-items-center">
                                                <StorageIcon />
                                                <h5 className='px-2'> Cost Efficiency </h5>
                                            </div>
                                            <p >
                                                Lower expenses by reducing errors and automating tasks.
                                            </p>
                                        </div>


                                    </div>

                                </Col>

                                <Col>
                                    <div>
                                        <div className='data-migration-features'>
                                            <div className="d-flex align-items-center">
                                                <StorageIcon />
                                                <h5 className='px-2'>Robust Data Security</h5>
                                            </div>
                                            <p >
                                                Ensure protection with advanced security measures.
                                            </p>
                                        </div>
                                        <div className='data-migration-features'>
                                            <div className="d-flex align-items-center">
                                                <StorageIcon />
                                                <h5 className='px-2'>Improved Data Integrity</h5>
                                            </div>
                                            <p >
                                                Enjoy reliable, accurate data regardless of business size.
                                            </p>
                                        </div>
                                        <div className='data-migration-features'>
                                            <div className="d-flex align-items-center">
                                                <StorageIcon />
                                                <h5 className='px-2'>Comprehensive Integration </h5>
                                            </div>
                                            <p >
                                                Covering API, cloud, data warehousing, e-commerce, and enterprise applications.
                                            </p>
                                        </div>


                                    </div>

                                </Col>
                            </Row>

                        </Col>
                        <Col lg={5}>
                            <div data-aos="fade-left">
                                <img src={require('../Assets/Home/Why Choose Integral-1.png')} className='w-100' alt='why-choose-us-img1' />
                            </div>
                        </Col>
                    </Row>

                    {/* <Row className='text-start'>
                        <Col lg={6}>
                        <Row>
                            <Col lg={9}>
                            <div className="features-data">
                        <h5>
                        Efficient Data Operations: 
                        </h5>
                        <p>
                        Simplify processes, freeing time for strategic initiatives. 
                        </p>
                       </div>

                       <div className="features-data">
                        <h5>
                        Unified Integration Across Sources: 
                        </h5>
                        <p>
                        Seamlessly connect diverse data for a cohesive landscape.
                        </p>
                       </div>

                       <div className="features-data">
                        <h5>
                        Cost Efficiency: 
                        </h5>
                        <p>
                        Lower expenses by reducing errors and automating tasks.
                        </p>
                       </div>
                       <div className="features-data">
                        <h5>
                        Robust Data Security: 
                        </h5>
                        <p>
                        Ensure protection with advanced security measures.
                        </p>
                       </div>

                       <div className="features-data">
                        <h5>
                        Improved Data Integrity: 
                        </h5>
                        <p>
                        Enjoy reliable, accurate data regardless of business size.
                        </p>
                       </div>

                       <div className="features-data">
                        <h5>
                        Comprehensive Integration Services: 
                        </h5>
                        <p>
                        Covering API, cloud, data warehousing, e-commerce, and enterprise applications.
                        </p>
                       </div>

                            </Col>
                        </Row>
                       

                        </Col>
                        <Col lg={6}>
                        <h1>
                            Video
                        </h1>
                        </Col>
                    </Row> */}
                </Container>
            </section>


            {/* <section className='mt-5'>
                <Container>
                    <Row>
                        <h2>Why choose us for data migration services?</h2>
                        <div className="bar"></div>
                    </Row>
                    <Row>
                        <Tabs
                            defaultActiveKey="Agile"
                            id="justify-tab-example"
                            className="mb-3 py-3   "
                            justify
                        >
                            <Tab eventKey="Agile" title="Agile data migration" className=''  >
                                <Row className='text-start'>
                                    <Col lg={7} className='alige'>
                                        <p>
                                            The entire process will be divided into sub-migrations with different timelines, goals, scope, and quality checks.
                                        </p>
                                        <ul>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>

                                                    Utilizes agile project methodology
                                                </p>
                                            </li>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                    Suitable for large applications with separate modules</p>
                                            </li>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                    Parallel operation of old and new systems</p>
                                            </li>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                    Data transfer in stages
                                                </p>
                                            </li>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                    Zero application downtime
                                                </p>
                                            </li>
                                        </ul>
                                    </Col>
                                    <Col lg={5} data-aos="fade-left">
                                        <img src={require('../Assets/DataMigration/agile-data-migration-img.jpg')} className='w-100 h-75' alt='alige' />
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="Cross" title="Cross-functional allied teams">
                                <Row className='text-start'>
                                    <Col lg={7} className='alige'>
                                        <p>
                                            Our team of professionals go hand-in-hand to contribute to the common goal. Businesses can benefit from diverse perspectives, expertise, improved communication and collaboration, and data-driven decision-making. Our data migration team is composed of
                                        </p>
                                        <ul>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>

                                                    Data owners and stewards
                                                </p>
                                            </li>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                    Project management team</p>
                                            </li>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                    Functional team</p>
                                            </li>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                    Technical team
                                                </p>
                                            </li>

                                        </ul>
                                    </Col>
                                    <Col lg={5}>
                                        <img src={require('../Assets/DataMigration/agile-data-migration-img.jpg')} className='w-100 h-75' alt='alige' />
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="Client" title="Client-focused">
                                <Row className='text-start'>
                                    <Col lg={7} className='alige'>
                                        <p>
                                            We prioritize customer requirements and their satisfaction above all. Thus, we create the best experience for them and build trust in our brand.
                                        </p>
                                        <ul>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>

                                                    Communication & collaboration
                                                </p>
                                            </li>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                    Transparency</p>
                                            </li>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                    Dedication</p>
                                            </li>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                    Real-time updates on the progress
                                                </p>
                                            </li>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                    Zero application downtime
                                                </p>
                                            </li>
                                        </ul>
                                    </Col>
                                    <Col lg={5}>
                                        <img src={require('../Assets/DataMigration/agile-data-migration-img.jpg')} className='w-100 h-75' alt='alige' />
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="Proven" title="Proven track record">
                                <Row className='text-start'>
                                    <Col lg={7} className='alige'>
                                        <p>
                                            We have a track record of project delivery for multiple industries and a stellar reputation for providing seamless data migration solutions for optimal performance, catering to various business needs with precision and efficiency.
                                        </p>
                                        <ul>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>

                                                    Customized data migration
                                                </p>
                                            </li>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                    Profound industry vertical experience</p>
                                            </li>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                    Rapid project delivery</p>
                                            </li>
                                            <li>
                                                <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                    Adoption of upgraded tools and technologies
                                                </p>
                                            </li>

                                        </ul>
                                    </Col>
                                    <Col lg={5}>
                                        <img src={require('../Assets/DataMigration/agile-data-migration-img.jpg')} className='w-100 h-75' alt='alige' />
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="Quality" title="Quality assurance">
                                <Row className='text-start'>
                                    <Col lg={7} className='alige'>
                                        <p>
                                            We ensure the quality of your migrated data through data quality assessment in which test scripts and test cases are created to ensure data accuracy, consistency, relevance, timeliness, completeness, and integrity during and after migration.
                                        </p>
                                        <ul>
                                            <Row>
                                                <Col lg={5}>
                                                    <li>
                                                        <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>

                                                            Test planning and control
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                            Executing test cases</p>
                                                    </li>
                                                    <li>
                                                        <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                            Data profiling</p>
                                                    </li>
                                                    <li>
                                                        <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                            Data auditing
                                                        </p>
                                                    </li>


                                                </Col>
                                                <Col lg={4}>
                                                    <li>
                                                        <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                            Data cleansing
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                            Data validation
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p><i class="fa fa-arrow-right px-2" aria-hidden="true"></i>
                                                            Data quality reporting
                                                        </p>
                                                    </li>
                                                </Col>
                                            </Row>
                                        </ul>
                                    </Col>
                                    <Col lg={5}>
                                        <img src={require('../Assets/DataMigration/agile-data-migration-img.jpg')} className='w-100 h-75' alt='alige' />
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Row>
                </Container>
            </section> */}



            {/* <section>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col lg={6}>
                            <img src={require('../Assets/Home/Why Choose Integral2.png')} className='w-100' alt='what- we-do' />
                        </Col>
                        <Col lg={6}>
                            <h2 className='fs-3 text-start'>
                                <span className='fs-2' style={{ color: "#E35B13" }}>
                                    Data Integration </span>  Improves  Your Business Strategies.
                            </h2>

                            <h4 className='py-3 text-start integra-revolu'>
                                Here's How It can Revolutionize Your Company:
                            </h4>
                            <div className="integration-poi mt-3">
                                <h5>
                                    Streamlined Data Opertaions
                                </h5>
                                <h5>
                                    Seamless Integration Across Sources
                                </h5>
                                <h5>
                                    Cost Optimization
                                </h5>
                                <h5>
                                    Enchanced Data Security
                                </h5>
                                <h5>
                                    Improved Data Quality
                                </h5>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section> */}

            <section className='cta mt-5'>
                <div>
                    <Container>
                        <Row className='cta-row'>
                            <Col lg={7}>
                                <div className='cta-content'>
                                    <h3>Looking for reliable and high-end data migration services?</h3>
                                    <Link to='/contact-us'><Button className='cta-btn' data-aos="fade-left">Let's Get Started</Button></Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </div>
    )
}