import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <div>
        {/* <!-- Footer Start --> */}
    <div class="container-fluid  text-light footer  py-5 wow fadeIn" data-wow-delay="0.1s">
        {/* <img src='https://policychayan.com/assets/images/footer-shape-1.png' alt='banner'/> */}
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-lg-3 our-office col-md-6">
                    <h4 class="text-white text-left mb-4">Our Office</h4>
                    <p class="mb-2"><i class="fa fa-map-marker me-3" aria-hidden="true"></i>Dubai Silicon Oasis, Mirpur 2 DDP,Building A2, United Arab Emirates</p>
                    <p class="mb-2"><i class="fa fa-phone me-3" aria-hidden="true"></i>+971 568421293</p>
                    <p class="mb-2"><i class="fa fa-envelope me-3"></i>info@integraldataanalytics.com</p>
                    <div class="d-flex pt-2">
                        <a class="btn btn-square btn-outline-light rounded-circle me-2" href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                        <a class="btn btn-square btn-outline-light rounded-circle me-2" href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                        <a class="btn btn-square btn-outline-light rounded-circle me-2" href="#"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
                        <a class="btn btn-square btn-outline-light rounded-circle me-2" href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <h4 class="text-white mb-4 text-start">Products</h4>
                    <Link to="/data-migration" class="btn btn-link"> <i class="fa fa-chevron-right me-3" aria-hidden="true"></i>Data Migration</Link>
                    <Link to="/data-analytics" class="btn btn-link">  <i class="fa fa-chevron-right me-3" aria-hidden="true"></i> Data Analytics</Link>
                    <Link to="/Smart-IG" class="btn btn-link">  <i class="fa fa-chevron-right me-3" aria-hidden="true"></i> Smart IG</Link>
                    <Link to="Saas" class="btn btn-link">  <i class="fa fa-chevron-right me-3" aria-hidden="true"></i> SAAS</Link>
                </div>
                <div class="col-lg-3 col-md-6">
                    <h4 class="text-white mb-4 text-start">Quick Links</h4>
                    <Link to="about-us" class="btn btn-link">  <i class="fa fa-chevron-right me-3" aria-hidden="true"></i> About Us</Link>
                    <Link to="contact-us" class="btn btn-link">  <i class="fa fa-chevron-right me-3" aria-hidden="true"></i> Contact Us</Link>
                    <a class="btn btn-link" href="/"><i class="fa fa-chevron-right me-3" aria-hidden="true"></i>Terms & Condition</a>
                    {/* <a class="btn btn-link" href="contact-us"><i class="fa fa-chevron-right me-3" aria-hidden="true"></i>Support</a> */}
                </div>
                <div class="col-lg-3 col-md-6 d-flex align-items-center justify-content-center ">
                    <div class="position-relative w-100 ">
                        <input class="form-control bg-white border-0 w-100 py-3 ps-4 pe-5" type="text"
                            placeholder="Your email"/>
                        <button type="button"
                            class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Footer End --> */}
    </div>
  )
}
