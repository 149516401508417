import React from 'react';
import { Col, Container,  Row } from 'react-bootstrap';
import './ContactUs.css';
import { FormComponent } from './FormComponent';
// import emailjs from '@emailjs/browser';

export const ContactUs = () => {
   

    return (
        <div>
            {/* Banner section */}
            <section>
                <div className="contact-banner text-center">
                    <h1 className='py-5'>Contact Us</h1>
                    <p></p>
                </div>
            </section>

            {/* Address section */}
            <section className="my-5 contact-addre">
                <Container fluid cl
                >
                    <div className="background-img">
                        <Row className="details-section">
                            <Col lg={3} className='mb-4'>
                                <div className="contact-cards">
                                    <div className="card-details ">
                                        <div className="icon-container ">
                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        </div>
                                        <div className="py-3 text-left justify-content-center align-items-center ">
                                            <h5>Visit Here</h5>
                                            <p>Dubai Silicon Oasis,  Mirpur 2 DDP, <br /> Building A2, United Arab Emirates </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} className='mb-4'>
                                <div className="contact-cards">
                                    <div className="card-details">
                                        <div className="icon-container ">
                                            <i className="fa fa-phone" aria-hidden="true"></i>
                                        </div>
                                        <div className="py-3 text-left">
                                            <h5>Call Here</h5>
                                            <p>+971 568421293</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} className='mb-4'>
                                <div className="contact-cards">
                                    <div className="card-details">
                                        <div className="icon-container">
                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                        </div>
                                        <div className="py-3">
                                            <h5>Mail Here</h5>
                                            <p>info@integraldataanalytics.com </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            {/* Map Section */}
            <section className="mt-5">
                <iframe title='map' className="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d115596.12940063782!2d55.388607!3d25.122873!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f640f1dcd4111%3A0xcb38e7be624792c3!2sDubai%20Silicon%20Oasis%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1713350485980!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </section>

            {/* Form section */}
            <section className="mb-5 py-5">
                <Container>
                    <Row className="text-center">
                        <h2>Get In Touch With Us</h2>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <img className='w-100' src="https://startp-next.envytheme.com/_next/static/media/contact-img.15a914b7.png" alt="contact-img" />
                        </Col>
                        <Col lg={6}>
                        <div className="form">
                       <FormComponent/>
                        </div>
                       

                            
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};




