import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'

export const Whatsapp = () => {
  return (
    <div>
      <FloatingWhatsApp 
      phoneNumber="+919949372331"        // Your phone number
      accountName="Integral"         // Name to be displayed
      avatar={require("../Assets/Footer/avatar.jpg")}     // Path to an avatar image
      allowClickAway={true}           // Close the widget when clicking outside
      notification={true}             // Enable/disable notification
      notificationDelay={60000}       // Delay for notification in milliseconds
      notificationSound={true}        // Enable/disable notification sound
      placeholder="Type a message..." // Placeholder text for the input field
      statusMessage="Typically replies within an hour" // Status message
      chatMessage="Hello! Type somethig to move to chat" // Initial chat message
      darkMode={false}   
      />
    </div>
  )
}
